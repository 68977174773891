import React from 'react'
import Accountheader from './Accountheader';
import Googleimg from '../images/gl.jpg'
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function Previoussiginup() {
    const navigate = useNavigate();
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase()); 
      }

    function goforsignup(){
        var useremail = $('#useremail').val();
        if(validateEmail(useremail)){
        localStorage.setItem('useremail',useremail);
        navigate('/signup_steptwo');
        }else{
            alert('Please insert a valid email.')
        }
    }

    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("deactivetopbar");
    }

    return (
        <div>
            <Accountheader/>
            <div  className="forms">

            <div class="pop-up" id="topbar"><span>Already have an account? <span class="bold"><Link to="/" >Sign In</Link></span></span> <i class="ri-close-fill close-btn" onClick={()=>{offTheBar('topbar')}}></i></div>

                <div  className="form">

                    <div  className="form-content">



                            <h3>Create Account</h3>

                            <div class="media-options">
                                <a href="https://retirement.jondaknows.com/google-oauth.php" class="field google">
                                    <img src={Googleimg} alt="" class="google-img"/>
                                    <span>Continue With Google</span>    
                                </a>
                            </div>
                            <div class="line"></div>
                            <div  className="field">
                                <input type="text" id="useremail"  className="fixtype" placeholder="name@domain.com" />
                            </div>

                            <div  className="field mb-30">
                                <button onClick={goforsignup}>Next</button>
                            </div>

                            



                    </div>

                </div>

                <div  className="privacy-section"><i  className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>
        </div>
    )
}

import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';

export default function Notification() {
    const [luid, setLuid] = useState(localStorage.getItem('uid'));
    const [notifications, setNotifications] = useState([]);
    const [keyval, setKeyval] = useState('');
    var keyvalue = '';

    useEffect(() => {
        const loadNotification = () => {
            const obj = { LUID: luid };

            $.ajax({
                type: 'POST',
                url: 'https://retirement.jondaknows.com/notifications_user.php',
                data: obj,
                cache: false,
                success: function (result) {
                    console.log(result);
                    if (result !== 'fail') {
                        setNotifications(result);
                    } else {
                        alert('Please check your email.');
                    }
                },
            });
        };

        const timer = setTimeout(loadNotification, 1000);

        return () => clearTimeout(timer);
    }, [luid]);


    const opencontent = (id) => {
        const selectedNotification = notifications[id];
        setKeyval(selectedNotification.pk);
        keyvalue = selectedNotification.pk;
       
        console.log(selectedNotification.pk)
        const updatedNotifications = notifications.map((notification, index) => {
            if (index === id) {
                return { ...notification, readstatus: "true" };
            }
            return notification;
        });
        setNotifications(updatedNotifications);
        updatelist();
    };

    const updatelist = () => {
        
        const obj = { LUID: luid, keyvalue:keyvalue };
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/updatenotification.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result !== 'fail') {
                    console.log(result);
                } else {
                    alert('cannot update.');
                }
            },
        });
    };

    const renderNotification = (notification, index) => {
        const { msgfrom, date, heading, content, readstatus } = notification;
        return (
            <div
                key={index}
                id={`content${index}`}
                className={`msg topbottongap border listitem ${readstatus === "false" ? '' : 'fadeblock'}`}
            >
                <div class="sender">

                    <div class="msg-from">Message From {msgfrom}</div>
            
                    <div class="row">
                        <div class="date">{date}</div>
                    </div>
            
                </div>
                <div class="msg-subject">{heading}</div>
                <div class="msg-txt">
                <p className="headingcontent" style={{ display: readstatus === "false" ? 'none' : 'inline-block' }}>
                    {content}
                </p>
                </div>
                <p className='nodisplay' id="pk">{notification.pk}</p>
                {readstatus === "false" && (
                    <div className="readmorelist">
                        <button onClick={() => opencontent(index)}>Read More</button>
                    </div>
                )}
            </div>
        );
    };


    return (
        <div>
            <Innerheader />
            <div class="container_small_half spacer"></div>
            <h3 class="align-item-center">Notifications</h3>
            <div class="container_small">
                <div class="messages-area" id="notificationlist">
                {notifications.map((notification, index) => renderNotification(notification, index))}
                </div>
            </div>
            <Innerfooter />
        </div>
    )
}

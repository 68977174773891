import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Returntostepone() {
    const navigate = useNavigate();
    setTimeout(returntostepone, 1000)
    function returntostepone(){
        navigate('/profilepartone');   
    }
  return (
    <div>
      
    </div>
  )
}

import React, { useEffect } from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';

export default function Profile_parttwo() {
    var dataarrr = [];
    var editmoce = 0;
    var trgfield_value = '';
    var tgrgfield_name = '';
    var near_future_value = '';
    var nest_egg_money_value = ''; 

    useEffect(() => {

        var luid = localStorage.getItem('uid');
        var obj = {
            LUID: luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/view_steptwoform.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result !== "fail") {
                    dataarrr = result;
                    $('#ss_husband').val(dataarrr[0].ss_husband);
                    $('#ss_wife').val(dataarrr[0].ss_wife);
                    $('#wages_husband').val(dataarrr[0].wages_husband);
                    $('#wages_wife').val(dataarrr[0].wages_wife);
                    $('#pension_husband').val(dataarrr[0].pension_husband);
                    $('#pension_wife').val(dataarrr[0].pension_wife);
                    $('#oi_husband').val(dataarrr[0].oi_husband);
                    $('#oi_wife').val(dataarrr[0].oi_wife);
                    $('#so_husband').val(dataarrr[0].so_husband);
                    $('#so_wife').val(dataarrr[0].so_wife);
                    $('#so_wife').val(dataarrr[0].so_wife);
                    $("input[name='amount_net_gross'][value='" + dataarrr[0].amount_net_gross + "'").prop('checked', true);
                    $('#monthly_expenses').val(dataarrr[0].monthly_expenses);
                    $("input[name='cash_flow_suff'][value='" + dataarrr[0].cash_flow_suff + "'").prop('checked', true);
                    $("input[name='majo_lifestyle'][value='" + dataarrr[0].majo_lifestyle + "'").prop('checked', true);
                    $("input[name='withdraw_ret_savings'][value='" + dataarrr[0].withdraw_ret_savings + "'").prop('checked', true);
                    $("input[name='large_purchase'][value='" + dataarrr[0].large_purchase + "'").prop('checked', true);
                    $("input[name='anticipant_significant'][value='" + dataarrr[0].anticipant_significant + "'").prop('checked', true);
                    $("input[name='contribute_to_charity'][value='" + dataarrr[0].contribute_to_charity + "'").prop('checked', true);
                    var nrfuture = dataarrr[0].near_future;
                    if (nrfuture !== '') {
                        var arr = nrfuture.split('|');
                        var len = arr.length;
                        for (var i = 0; i < len; i++) {
                            var vals = arr[i];
                            if (vals === 'Buy a home') {
                                $('#buythome').prop('checked', true)
                            }
                            if (vals === 'Care for a parent') {
                                $('#parentcare').prop('checked', true)
                            }
                            if (vals === 'Sell a home') {
                                $('#sellhome').prop('checked', true)
                            }
                            if (vals === 'Start/Expand a business') {
                                $('#startbusiness').prop('checked', true)
                            }
                            if (vals === 'Sell a property') {
                                $('#sellproperty').prop('checked', true)
                            }
                            if (vals === 'Improve a home') {
                                $('#improvehome').prop('checked', true)
                            }
                            if (vals === 'Pay off Debt') {
                                $('#paydebt').prop('checked', true)
                            }
                            if (vals === 'Receive an inheritance') {
                                $('#receive_inharitance').prop('checked', true)
                            }
                            if (vals === 'Retire') {
                                $('#retire').prop('checked', true)
                            }
                            if (vals === 'Start a part-time job') {
                                $('#part_time_job').prop('checked', true)
                            }
                            if (vals === 'Purchase a property') {
                                $('#purchase_property').prop('checked', true)
                            }
                            if (vals === 'Help fund education costs for a family member') {
                                $('#help_education_fund').prop('checked', true)
                            }
                            if (vals === 'Other') {
                                $('#near_future_other').prop('checked', true)
                            }

                        }
                    }
                    $('#near_future_desc').val(dataarrr[0].near_future_desc);
                    var buy_a_home = dataarrr[0].buy_a_home
                    if (buy_a_home !== '') {
                        $('#buy_a_home_check').prop('checked', true)
                        $('#buy_a_home').val(buy_a_home);
                        $('#buy_a_home').removeAttr('readonly');
                    }
                    var cds_value = dataarrr[0].cds_value
                    if (cds_value !== '') {
                        $('#cds_check').prop('checked', true)
                        $('#cds_value').val(cds_value);
                        $('#cds_value').removeAttr('readonly');
                    }
                    var mutual_funds = dataarrr[0].mutual_funds
                    if (mutual_funds !== '') {
                        $('#mutual_fund_check').prop('checked', true)
                        $('#mutual_funds').val(mutual_funds);
                        $('#mutual_funds').removeAttr('readonly');
                    }
                    var brokerage_account = dataarrr[0].brokerage_account
                    if (brokerage_account !== '') {
                        $('#brokerage_acc_check').prop('checked', true)
                        $('#brokerage_account').val(brokerage_account);
                        $('#brokerage_account').removeAttr('readonly');
                    }
                    var business_interest = dataarrr[0].business_interest
                    if (business_interest !== '') {
                        $('#business_intrst_check').prop('checked', true)
                        $('#business_interest').val(business_interest);
                        $('#business_interest').removeAttr('readonly');
                    }
                    var annuities = dataarrr[0].annuities
                    if (annuities !== '') {
                        $('#annuities_check').prop('checked', true)
                        $('#annuities').val(annuities);
                        $('#annuities').removeAttr('readonly');
                    }
                    var retirement_account = dataarrr[0].retirement_account
                    if (retirement_account !== '') {
                        $('#retirement_acc_check').prop('checked', true)
                        $('#retirement_account').val(retirement_account);
                        $('#retirement_account').removeAttr('readonly');
                    }
                    var ira_tsa = dataarrr[0].ira_tsa
                    if (ira_tsa !== '') {
                        $('#iras_check').prop('checked', true)
                        $('#ira_tsa').val(ira_tsa);
                        $('#ira_tsa').removeAttr('readonly');
                    }
                    var life_insurance = dataarrr[0].life_insurance
                    if (life_insurance !== '') {
                        $('#lic_check').prop('checked', true)
                        $('#life_insurance').val(life_insurance);
                        $('#life_insurance').removeAttr('readonly');
                    }
                    var ltc_insurance = dataarrr[0].ltc_insurance
                    if (ltc_insurance !== '') {
                        $('#LTC_insurance_check').prop('checked', true)
                        $('#ltc_insurance').val(ltc_insurance);
                        $('#ltc_insurance').removeAttr('readonly');
                    }
                    var promissory_notes = dataarrr[0].promissory_notes
                    if (promissory_notes !== '') {
                        $('#promissory_notes_check').prop('checked', true)
                        $('#promissory_notes').val(promissory_notes);
                        $('#promissory_notes').removeAttr('readonly');
                    }
                    var other_assets = dataarrr[0].other_assets
                    if (other_assets !== '') {
                        $('#other_assets_check').prop('checked', true)
                        $('#other_assets').val(other_assets);
                        $('#other_assets').removeAttr('readonly');
                    }
                    $('#prop_home_value').val(dataarrr[0].prop_home_value);
                    $('#prop_balanced_own1').val(dataarrr[0].prop_balanced_own1);
                    $('#prop_payment1').val(dataarrr[0].prop_payment1);
                    $('#prop_payoff_date1').val(dataarrr[0].prop_payoff_date1);

                    $('#prop_autos').val(dataarrr[0].prop_autos);
                    $('#prop_balanced_own2').val(dataarrr[0].prop_balanced_own2);
                    $('#prop_payment2').val(dataarrr[0].prop_payment2);
                    $('#prop_payoff_date2').val(dataarrr[0].prop_payoff_date2);

                    $('#prop_rental').val(dataarrr[0].prop_rental);
                    $('#prop_balanced_own3').val(dataarrr[0].prop_balanced_own3);
                    $('#prop_payment3').val(dataarrr[0].prop_payment3);
                    $('#prop_payoff_date3').val(dataarrr[0].prop_payoff_date3);

                    $('#add_info_retired').val(dataarrr[0].add_info_retired);
                    $('#add_info_acquire_wealth').val(dataarrr[0].add_info_acquire_wealth);
                    $('#add_info_acquire_wealth2').val(dataarrr[0].add_info_acquire_wealth2);
                    $('#add_info_gets_cared').val(dataarrr[0].add_info_gets_cared);
                    var nestegg = dataarrr[0].nest_egg_money;
                    if (nestegg !== '') {
                        var arr = nestegg.split('|');
                        var len = arr.length;
                        for (var i = 0; i < len; i++) {
                            var vals = arr[i];
                            if (vals === 'SAFETY') {
                                $('#safely').prop('checked', true)
                            }
                            if (vals === 'LIQUIDITY') {
                                $('#liquidity').prop('checked', true)
                            }
                            if (vals === 'GROWTH') {
                                $('#growth').prop('checked', true)
                            }
                            if (vals === 'INCOME') {
                                $('#income').prop('checked', true)
                            }


                        }
                    }
                    $("input[name='investment_knowledge'][value='" + dataarrr[0].investment_knowledge + "'").prop('checked', true);
                    $('#client_health').val(dataarrr[0].client_health);
                    $('#spouse_health').val(dataarrr[0].spouse_health);

                }
            },
        });


        gofornewstyle();
        function gofornewstyle() {
            var len = 82;
            for (var i = 1; i < len; i++) {
                var nid = 'element-' + i;
                var getarr = $('#' + nid + ' input').attr('type');
                if (getarr !== 'radio' || getarr !== 'checkbox') {
                    $('#' + nid + ' input').attr('readonly', 'true');
                    $('#' + nid + ' input').addClass('makeptag');
                }
                if (getarr === 'radio' || getarr === 'checkbox') {
                    $('#' + nid + ' input').attr('disabled', 'true');
                }
                $('#' + nid + ' textarea').attr('readonly', 'true');
                $('#' + nid + ' textarea').addClass('makeptag');

            }
            //$('#liquidity').prop('checked', true);
        }
    })
    function readytoedit(id) {
        var trgedit = 'element-' + id;
        $('#' + trgedit + ' .each_editbtn').css('display', 'none')
        $('#' + trgedit + ' .eachsavebtn').css('display', 'block')
        var exttext = $('#' + trgedit + ' input').val();
        $('#' + trgedit + ' input').attr('prevtext', exttext);
        var getarr = $('#' + trgedit + ' input').attr('type');
        if (getarr !== 'radio' || getarr !== 'checkbox') {
            $('#' + trgedit + ' input').removeAttr('readonly', 'true');
            $('#' + trgedit + ' input').removeClass('makeptag');
        }
        if (getarr === 'radio' || getarr === 'checkbox') {
            $('#' + trgedit + ' input').removeAttr('disabled');
        }
        $('#' + trgedit + ' textarea').removeAttr('readonly', 'true');
        $('#' + trgedit + ' textarea').removeClass('makeptag');


    }

    function closeedit(id) {
        var trgedit = 'element-' + id;
        $('#' + trgedit + ' .each_editbtn').css('display', 'block')
        $('#' + trgedit + ' .eachsavebtn').css('display', 'none')
        var getarr = $('#' + trgedit + ' input').attr('type');
        if (getarr !== 'radio') {
            var prevtext = $('#' + trgedit + ' input').attr('prevtext');
            $('#' + trgedit + ' input').val(prevtext);
            $('#' + trgedit + ' input').attr('readonly', 'true');
            $('#' + trgedit + ' input').addClass('makeptag');
        }
        if (getarr == 'radio') {
            $('#' + trgedit + ' .radiocover').css('display', 'block')
        }
    }
    function readytosave(id) {
        var trgedit = 'element-' + id;
        $('#' + trgedit + ' .each_editbtn').css('display', 'block')
        $('#' + trgedit + ' .eachsavebtn').css('display', 'none')
        var getarr = $('#' + trgedit + ' input').attr('type');
        if (getarr !== 'radio') {
            $('#' + trgedit + ' input').attr('readonly', 'true');
            $('#' + trgedit + ' input').addClass('makeptag');
        }
        if (getarr === 'radio' || getarr === 'checkbox') {
            $('#' + trgedit + ' input').attr('disabled', 'true');
        }

        $('#' + trgedit + ' textarea').attr('readonly', 'true');
        $('#' + trgedit + ' textarea').addClass('makeptag');

        var targetvalue = $('#' + trgedit + ' input').val();
        var luid = localStorage.getItem('uid');
        var trgfield = $('#' + trgedit + ' input').attr('id');
        var obj = {
            trgvalue: targetvalue,
            LUID: luid,
            trgfield: trgfield
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/singleupdate.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result.trim() !== "fail") {
                    console.log(result);
                    alert('New value has been saved.')
                }
            },
        });

    }

    function makeeditable(){
        if(editmoce===0){
            var trgcount = 92;
        for(var i=1;i<trgcount;i++){
            var tgrgcls = 'input'+i;
            $('.'+tgrgcls).removeAttr('readonly');
            $('.'+tgrgcls).removeClass('makeptag');  
            $('.'+tgrgcls).removeAttr('disabled');       
        }
        $('.radiocover').css('display','none')
        $('#editform').text('Cancel');
        editmoce = 1;
        }else{
            var trgcount = 41;
        for(var i=1;i<trgcount;i++){
            var tgrgcls = 'input'+i;
            $('.'+tgrgcls).attr('readonly','true');
            $('.'+tgrgcls).addClass('makeptag');
            $('.'+tgrgcls).attr('disabled', 'true');
        }
        $('.radiocover').css('display','block')
        $('#editform').text('Edit')
        editmoce = 0;
        }     

    }

    

    function autosavevalues(type,id){
        var luid =  localStorage.getItem('uid');
        if(type==='text'){
            trgfield_value = $('#'+id).val();
            tgrgfield_name = id
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }else if(type==='radio'){
            trgfield_value = $("input[name='"+id+"']:checked").val();
            tgrgfield_name = id;
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }

    }
    
    function savetoserver(val,fldname,uid){
        var obj = {
            trgvalue:val,
            LUID:uid,
            trgfield:fldname
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/autosavesteptwo.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result.trim()!=="fail"){
                //                          
                }
            },
            });
    }


    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return '';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }

    function convertDollarToNumber(dollarStr) {
        // Remove the dollar sign
        let numberStr = dollarStr.replace('$', '');
        // Remove any commas
        numberStr = numberStr.replace(/,/g, '');
        // Convert to float
        let number = parseFloat(numberStr);
        return number;
    }

    function tocurrency(id){ 
        var luid =  localStorage.getItem('uid');       
        var pricetext = $('#'+id).val();
        var coverttonum = convertDollarToNumber(pricetext);
       var priceamt = parseInt(coverttonum);
       var finalprice = formatMoney(priceamt);
       $('#'+id).val(finalprice);
       savetoserver(finalprice,id,luid);
    }


    function formatSSN(ssn) {
        // Remove any non-digit characters
        ssn = ssn.replace(/\D/g, '');
        
        // Check if the SSN has exactly 9 digits
        if (ssn.length !== 9) {
            alert('SSN must be exactly 9 digits. Please check your SSN');
            return '';
        }
    
        // Format the SSN as XXX-XX-XXXX
        return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }

    function formatssn(id){
        var luid =  localStorage.getItem('uid');       
        var ssntext = $('#'+id).val();
        var finalssn = formatSSN(ssntext);
        $('#'+id).val(finalssn);
        savetoserver(finalssn,id,luid);
    }

    function savefornestegg(){
        nest_egg_money_value = '';
        var luid =  localStorage.getItem('uid');
        if ($('#safely').prop('checked')) {
            nest_egg_money_value += 'SAFETY|'
        }
        if ($('#liquidity').prop('checked')) {
            nest_egg_money_value += 'LIQUIDITY|'
        }
        if ($('#growth').prop('checked')) {
            nest_egg_money_value += 'GROWTH|'
        }
        if ($('#income').prop('checked')) {
            nest_egg_money_value += 'INCOME|'
        }
        tgrgfield_name = 'nest_egg_money';
        savetoserver(nest_egg_money_value,tgrgfield_name,luid)
    }


    function activeinactivefield(chkbox,txtbox){
        var luid =  localStorage.getItem('uid');
        if ($('#'+chkbox).prop('checked')) {            
            $('#'+txtbox).removeAttr('readonly')
        }else{
            $('#'+txtbox).attr('readonly','true');
            $('#'+txtbox).val('');
            var txtval = $('#'+txtbox).val();
            savetoserver(txtval,txtbox,luid);
        }
    }

    function savefornestegg(){
        nest_egg_money_value = '';
        var luid =  localStorage.getItem('uid');
        if ($('#safely').prop('checked')) {
            nest_egg_money_value += 'SAFETY|'
        }
        if ($('#liquidity').prop('checked')) {
            nest_egg_money_value += 'LIQUIDITY|'
        }
        if ($('#growth').prop('checked')) {
            nest_egg_money_value += 'GROWTH|'
        }
        if ($('#income').prop('checked')) {
            nest_egg_money_value += 'INCOME|'
        }
        tgrgfield_name = 'nest_egg_money';
        savetoserver(nest_egg_money_value,tgrgfield_name,luid)
    }


    function setnearfuture(){
        near_future_value = '';
        var luid =  localStorage.getItem('uid');
        if ($('#buythome').prop('checked')) {
            near_future_value += 'Buy a home|'
        }
        if ($('#parentcare').prop('checked')) {
            near_future_value += 'Care for a parent|'
        }
        if ($('#sellhome').prop('checked')) {
            near_future_value += 'Sell a home|'
        }
        if ($('#startbusiness').prop('checked')) {
            near_future_value += 'Start/Expand a business|'
        }
        if ($('#sellproperty').prop('checked')) {
            near_future_value += 'Sell a property|'
        }
        if ($('#improvehome').prop('checked')) {
            near_future_value += 'Improve a home|'
        }
        if ($('#improvehome').prop('checked')) {
            near_future_value += 'Improve a home|'
        }
        if ($('#paydebt').prop('checked')) {
            near_future_value += 'Pay off Debt|'
        }
        if ($('#receive_inharitance').prop('checked')) {
            near_future_value += 'Receive an inheritance|'
        }
        if ($('#retire').prop('checked')) {
            near_future_value += 'Retire|'
        }
        if ($('#part_time_job').prop('checked')) {
            near_future_value += 'Start a part-time job|'
        }
        if ($('#purchase_property').prop('checked')) {
            near_future_value += 'Purchase a property|'
        }
        if ($('#help_education_fund').prop('checked')) {
            near_future_value += 'Help fund education costs for a family member|'
        }
        if ($('#near_future_other').prop('checked')) {
            near_future_value += 'Other|'

        }
        tgrgfield_name = 'near_future';
        savetoserver(near_future_value,tgrgfield_name,luid)

    }


    return (
        <div>
            <Innerheader />

            <div className="container_small profile_part_btn h90">
                <div className='container_small_fix nopadding'>
                    <span><Link to="/profilepartone">Personame/ Contact Information</Link></span>
                    <span><button className="selectedbtn">Cash Flow</button></span>
                    <span><Link to="/profilepartthree" >Concerns</Link></span>
                    <span className='toright'><button className="transselectedbtn" onClick={makeeditable}id="editform">Edit</button></span>
                    
                </div>
            </div>
            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                CASHFLOW
            </div>
            <div className="container_small_fix row form-heading">
                <span>Please list monthly income from each source:</span>
            </div>

            <div className="container_small_fix row">

                <div className="colmn-form column-half">

                    <span>Social Security</span>

                    <div className="fullwidth row">
                        <div className="input-form-part-2" id="element-0">
                            <label>Husband *</label>
                            <input type="text" id="ss_husband" className='input1' placeholder="SSN Type Here... *" onChange={()=>{autosavevalues('text','ss_husband')}} onBlur={()=>{formatssn('ss_husband')}} />
                        </div>

                        <div className="input-form-part-2" id="element-1">
                            <label  >Wife *</label>
                            <input type="text" className='input2' id="ss_wife" placeholder="SSN Type Here... *" onChange={()=>{autosavevalues('text','ss_wife')}} onBlur={()=>{formatssn('ss_wife')}}/>
                        </div>
                    </div>


                    <span>Pension</span>

                    <div className="fullwidth row">
                        <div className="input-form-part-2" id="element-2">
                            <label  >Husband *</label>
                            <input type="text" className='input3' id="pension_husband" placeholder="Type Here... *"  onChange={()=>{autosavevalues('text','pension_husband')}} onBlur={()=>{tocurrency('pension_husband')}}/>
                        </div>

                        <div className="input-form-part-2" id="element-3">
                            <label  >Wife *</label>
                            <input type="text" className='input4' id="pension_wife" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','pension_wife')}} onBlur={()=>{tocurrency('pension_wife')}}/>
                        </div>
                    </div>


                    <span>(Survivor Options)</span>

                    <div className="fullwidth row" id="element-4">
                        <div className="input-form-part-2">
                            <label  >Husband *</label>
                            <input type="text" className='input5' id="so_husband" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','so_husband')}}/>
                        </div>

                        <div className="input-form-part-2" id="element-5">
                            <label  >Wife *</label>
                            <input type="text" className='input6' id="so_wife" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','so_wife')}}/>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span >Is your current cash flow sufficient and <br />comfortable?</span>

                                <div className="benefit-options" id="element-6">
                                    <div className="whole-check">
                                        <input type="radio" className='input7' name="cash_flow_suff" value="Yes"   onChange={()=>{autosavevalues('radio','cash_flow_suff')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-7">
                                        <input type="radio" className='input8' name="cash_flow_suff" value="No"  onChange={()=>{autosavevalues('radio','cash_flow_suff')}}/> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Do you take any withdrawals from your <br />retirement savings to meet your current<br />budget?</span>

                                <div className="benefit-options" id="element-8">
                                    <div className="whole-check">
                                        <input type="radio" className='input9' name="withdraw_ret_savings" value="Yes"    onChange={()=>{autosavevalues('radio','withdraw_ret_savings')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-9">
                                        <input type="radio" className='input10' name="withdraw_ret_savings" value="No"  onChange={()=>{autosavevalues('radio','withdraw_ret_savings')}}/> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Do you anticipate any significant <br />changes in cash flow ?</span>

                                <div className="benefit-options">
                                    <div className="whole-check" id="element-10">
                                        <input type="radio" className='input11'name="anticipant_significant" value="Yes"   onChange={()=>{autosavevalues('radio','anticipant_significant')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-11">
                                        <input type="radio" className='input12' name="anticipant_significant" value="No" onChange={()=>{autosavevalues('radio','anticipant_significant')}}/> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="colmn-form column-half">

                    <span>Wages</span>

                    <div className="fullwidth row">
                        <div className="input-form-part-2 column-2" id="element-12">
                            <label  >Husband *</label>
                            <input type="text" className='input13' id="wages_husband" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','wages_husband')}} onBlur={()=>{tocurrency('wages_husband')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-13">
                            <label  >Wife *</label>
                            <input type="text" className='input14' id="wages_wife" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','wages_wife')}} onBlur={()=>{tocurrency('wages_wife')}}/>
                        </div>
                    </div>

                    <span>Other Income</span>

                    <div className="fullwidth row">
                        <div className="input-form-part-2 column-2" id="element-14">
                            <label  >Husband *</label>
                            <input type="text" className='input15' id="oi_husband" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','oi_husband')}} onBlur={()=>{tocurrency('oi_husband')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-15">
                            <label  >Wife *</label>
                            <input type="text" className='input16' id="oi_wife" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','oi_wife')}} onBlur={()=>{tocurrency('oi_wife')}}/>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Are these amounts net or gross?</span>

                                <div className="benefit-options">
                                    <div className="gross" id="element-16">
                                        <input type="radio" className='input17' name="amount_net_gross" value="Gross"    onChange={()=>{autosavevalues('radio','amount_net_gross')}}/> Gross
                                    </div>
                                    <div className="net" id="element-17">
                                        <input type="radio" className='input18' name="amount_net_gross" value="Net" onChange={()=>{autosavevalues('radio','amount_net_gross')}}/> Net
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="fullwidth input-life-insurance">
                        <div className="insurance" id="element-18">
                            <span>How much are your monthly expenses?</span>
                            <input type="text" className='input19' id="monthly_expenses" placeholder="Type here..." onChange={()=>{autosavevalues('text','monthly_expenses')}} onBlur={()=>{tocurrency('monthly_expenses')}}/>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Are you planning any major lifestyle <br />changes?</span>

                                <div className="benefit-options">
                                    <div className="whole-check" id="element-19">
                                        <input type="radio" className='input20' name="majo_lifestyle" value="Yes"   onChange={()=>{autosavevalues('radio','majo_lifestyle')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-20">
                                        <input type="radio" className='input21' name="majo_lifestyle" value="No" onChange={()=>{autosavevalues('radio','majo_lifestyle')}}/> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Do you foresee any large purchases <br />greater than
                                    $5,000 within the next <br />three years ?</span>

                                <div className="benefit-options">
                                    <div className="whole-check" id="element-21">
                                        <input type="radio" className='input22' name="large_purchase" value="Yes"  onChange={()=>{autosavevalues('radio','large_purchase')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-22">
                                        <input type="radio" className='input23' name="large_purchase" value="No"  onChange={()=>{autosavevalues('radio','large_purchase')}}/> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="input-form">

                            <div className="checkbox-death-benefit">
                                <span>Do you contribute to charity?</span>

                                <div className="benefit-options">
                                    <div className="whole-check" id="element-23">
                                        <input type="radio" className='input24' name="contribute_to_charity" value="Yes"   onChange={()=>{autosavevalues('radio','contribute_to_charity')}}/> Yes
                                    </div>
                                    <div className="term-check" id="element-24">
                                        <input type="radio" className='input25' name="contribute_to_charity" value="No" onChange={()=>{autosavevalues('radio','contribute_to_charity')}} /> No
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            </div>

           

            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                LIFE EVENTS
            </div>

            <div className="container_small_fix row form-heading">
                <span>In the near future I expect to: (Please check all that apply)</span>
            </div>

            <div className="container_small_fix">

                <div className="row">
                    <div className="column-2 f-2 w400">
                        <div className="check" id="element-25">
                            <input type="checkbox" className='input26' id="buythome"  onChange={setnearfuture}/> Buy a home
                        </div>

                        <div className="check" id="element-26">
                            <input type="checkbox" className='input27' id="parentcare" onChange={setnearfuture}/> Care for a parent
                        </div>

                        <div className="check" id="element-27">
                            <input type="checkbox" id="sellhome" className='input28' onChange={setnearfuture}/> Sell a home
                        </div>

                        <div className="check" id="element-28">
                            <input type="checkbox" className='input29' id="startbusiness" onChange={setnearfuture}/> Start/Expand a business
                        </div>

                        <div className="check" id="element-29">
                            <input type="checkbox" className='input30' id="sellproperty" onChange={setnearfuture}/> Sell a property
                        </div>

                        <div className="check" id="element-30">
                            <input type="checkbox" className='input31'  id="improvehome" onChange={setnearfuture}/> Improve a home
                        </div>
                    </div>

                    <div className="column-2 f-2 w510" >

                        <div className="check" id="element-31">
                            <input type="checkbox" id="paydebt" className='input32'  onChange={setnearfuture}/> Pay off Debt
                        </div>

                        <div className="check" id="element-32">
                            <input type="checkbox" className='input33'  id="receive_inharitance" onChange={setnearfuture}/> Receive an inheritance
                        </div>

                        <div className="check" id="element-33">
                            <input type="checkbox" className='input34'  id="retire" onChange={setnearfuture}/> Retire
                        </div>

                        <div className="check" id="element-34">
                            <input type="checkbox" className='input35'  id="part_time_job" onChange={setnearfuture}/> Start a part-time job
                        </div>

                        <div className="check" id="element-35">
                            <input type="checkbox" className='input36'  id="purchase_property" onChange={setnearfuture}/> Purchase a property
                        </div>

                        <div className="check" id="element-36">
                            <input type="checkbox" className='input37'  id="help_education_fund" onChange={setnearfuture}/> Help fund education costs for a family member
                        </div>
                    </div>

                </div>

                <div className="column-2 other-options">
                    <div className="row">
                        <div className="check" id="element-37">
                            <input type="checkbox" className='input38'  id="near_future_other" onChange={setnearfuture}/> Other
                        </div>
                    </div>

                    <div className="text-area-other" id="element-38">
                        <textarea rows="4" className='input39'  id="near_future_desc" cols="50" placeholder="Type Here..." onChange={()=>{autosavevalues('text','near_future_desc')}}></textarea>
                    </div>
                </div>

            </div>
            </div>
            

            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                ASSETS
            </div>

            <div className="container_small_fix row form-heading">
                <span>Please check of the accounts you currently hold, note the approximate value and bring in the latest statement.</span>
            </div>
            <div className="container_small_fix row">
                <div>
                    <div className="row">
                        <div className="check" id="element-39">
                            <input type="checkbox" className='input40'  id="buy_a_home_check" onChange={()=>{activeinactivefield('buy_a_home_check','buy_a_home')}}/> Buy a home
                        </div>

                        <div className="textbox-other" id="element-40">
                            <input type="text" id="buy_a_home" className='input41' placeholder="Type Here... *" onChange={()=>{autosavevalues('text','buy_a_home')}} readOnly onBlur={()=>{tocurrency('buy_a_home')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-41">
                            <input type="checkbox" className='input42' id="cds_check" onChange={()=>{activeinactivefield('cds_check','cds_value')}} /> CDs
                        </div>

                        <div className="textbox-other" id="element-42">
                            <input type="text" className='input43' id="cds_value" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','cds_value')}} readOnly onBlur={()=>{tocurrency('cds_value')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-43">
                            <input type="checkbox" className='input44' id="mutual_fund_check" onChange={()=>{activeinactivefield('mutual_fund_check','mutual_funds')}}/> Mutual Funds / Stocks / Bonds
                        </div>

                        <div className="textbox-other" id="element-44">
                            <input type="text" className='input45' id="mutual_funds" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','mutual_funds')}} readOnly onBlur={()=>{tocurrency('mutual_funds')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-45">
                            <input type="checkbox" className='input46' id="brokerage_acc_check" onChange={()=>{activeinactivefield('brokerage_acc_check','brokerage_account')}}/> Brokerage Accounts
                        </div>

                        <div className="textbox-other" id="element-46">
                            <input type="text" className='input47' id="brokerage_account" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','brokerage_account')}} readOnly onBlur={()=>{tocurrency('brokerage_account')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-47">
                            <input type="checkbox" className='input48' id="business_intrst_check" onChange={()=>{activeinactivefield('business_intrst_check','business_interest')}}/> Business Interest
                        </div>

                        <div className="textbox-other" id="element-48">
                            <input type="text" className='input49' id="business_interest" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','business_interest')}} readOnly onBlur={()=>{tocurrency('business_interest')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-49">
                            <input type="checkbox" className='input50' id="annuities_check" onChange={()=>{activeinactivefield('annuities_check','annuities')}}/> Annuities
                        </div>

                        <div className="textbox-other" id="element-50">
                            <input type="text" className='input51' id="annuities" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','annuities')}} readOnly onBlur={()=>{tocurrency('annuities')}}/>
                        </div>
                    </div>

                </div>

                <div>
                    <div className="row">
                        <div className="check" id="element-51">
                            <input type="checkbox" className='input52' id="retirement_acc_check" onChange={()=>{activeinactivefield('retirement_acc_check','retirement_account')}}/> Retirement Accounts from Work
                        </div>

                        <div className="textbox-other" id="element-52">
                            <input type="text" className='input53' id="retirement_account" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','retirement_account')}} readOnly onBlur={()=>{tocurrency('retirement_account')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-53">
                            <input type="checkbox" className='input54' id="iras_check" onChange={()=>{activeinactivefield('iras_check','ira_tsa')}}/> IRAs / 401(K) / 403(B) / Keoghs / TSAs
                        </div>

                        <div className="textbox-other" id="element-54">
                            <input type="text" className='input55' id="ira_tsa" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','ira_tsa')}} readOnly onBlur={()=>{tocurrency('ira_tsa')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-55">
                            <input type="checkbox" className='input56' id="lic_check" onChange={()=>{activeinactivefield('lic_check','life_insurance')}}/> Life Insurance
                        </div>

                        <div className="textbox-other" id="element-56">
                            <input type="text" className='input57' id="life_insurance" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','life_insurance')}} readOnly onBlur={()=>{tocurrency('life_insurance')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-57">
                            <input type="checkbox" className='input58' id="LTC_insurance_check" onChange={()=>{activeinactivefield('LTC_insurance_check','ltc_insurance')}}/> Long-Term Care Insurance
                        </div>

                        <div className="textbox-other" id="element-58">
                            <input type="text" className='input59' id="ltc_insurance" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','ltc_insurance')}} readOnly onBlur={()=>{tocurrency('ltc_insurance')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-59">
                            <input type="checkbox" className='input60' id="promissory_notes_check" onChange={()=>{activeinactivefield('promissory_notes_check','promissory_notes')}}/> Promissory Notes / Contract for Deed
                        </div>

                        <div className="textbox-other" id="element-60">
                            <input type="text" className='input61' id="promissory_notes" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','promissory_notes')}} onBlur={()=>{tocurrency('promissory_notes')}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="check" id="element-61">
                            <input type="checkbox" className='input62' id="other_assets_check" onChange={()=>{activeinactivefield('other_assets_check','other_assets')}} /> Other Assets
                        </div>

                        <div className="textbox-other" id="element-62">
                            <input type="text" className='input63' id="other_assets" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','other_assets')}} onBlur={()=>{tocurrency('other_assets')}}/>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            <div className='sectionholder'>
                <div className="container_small_fix row form-heading area-title">
                   PROPERTY
                </div>
            <div className="container_small_fix row">

                <div className="colmn-form fullwidth">


                    <div className="form-n row mb-20" >
                        <div className="input-form-part-2 column-2" id="element-63">
                            <label>Home Value $</label>
                            <input type="text" className='input64' id="prop_home_value" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_home_value')}} onBlur={()=>{tocurrency('prop_home_value')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-64">
                            <label  >Balance Owed</label>
                            <input type="text" className='input65' id="prop_balanced_own1" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_balanced_own1')}} onBlur={()=>{tocurrency('prop_balanced_own1')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-65">
                            <label  >Payment</label>
                            <input type="text" className='input66' id="prop_payment1" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_payment1')}} onBlur={()=>{tocurrency('prop_payment1')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-66">
                            <label  >Payoff date</label>
                            <input type="date" className='input67' id="prop_payoff_date1"  onChange={()=>{autosavevalues('text','prop_payoff_date1')}} />
                        </div>
                    </div>

                    <div className="form-n row mb-20" >
                        <div className="input-form-part-2 column-2" id="element-67">
                            <label  >Autos and Personal Property $</label>
                            <input type="text" className='input68' id="prop_autos" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_autos')}} onBlur={()=>{tocurrency('prop_autos')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-68">
                            <label  >Balance Owed</label>
                            <input type="text" className='input69' id="prop_balanced_own2" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_balanced_own2')}} onBlur={()=>{tocurrency('prop_balanced_own2')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-69">
                            <label  >Payment</label>
                            <input type="text" className='input70' id="prop_payment2" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_payment2')}} onBlur={()=>{tocurrency('prop_payment2')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-70">
                            <label  >Payoff date</label>
                            <input type="date" className='input71' id="prop_payoff_date2"  onChange={()=>{autosavevalues('text','prop_payoff_date2')}} />
                        </div>
                    </div>

                    <div className="form-n row mb-20" >
                        <div className="input-form-part-2 column-2" id="element-71">
                            <label  >Rental/Add’l Properties $</label>
                            <input type="text" className='input72' id="prop_rental" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_rental')}} onBlur={()=>{tocurrency('prop_rental')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-72">
                            <label  >Balance Owed</label>
                            <input type="text" className='input73' id="prop_balanced_own3" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_balanced_own3')}} onBlur={()=>{tocurrency('prop_balanced_own3')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-73">
                            <label  >Payment</label>
                            <input type="text" className='input74' id="prop_payment3" placeholder="Dollar value" onChange={()=>{autosavevalues('text','prop_payment3')}} onBlur={()=>{tocurrency('prop_payment3')}}/>
                        </div>

                        <div className="input-form-part-2 column-2" id="element-74">
                            <label  >Payoff date</label>
                            <input type="date" className='input75' id="prop_payoff_date3" onChange={()=>{autosavevalues('text','prop_payoff_date3')}} />
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className='sectionholder'>
                <div className="container_small_fix row form-heading area-title">
                ADD'L INFORMATION
            </div>

            <div className="container_small_fix row form-heading">
                <span>Please check of the accounts you currently hold, note the approximate value and bring in the latest statement.</span>
            </div>

            <div className="container_small_fix row">
                <div>
                    <div className="textbox-other column-2" id="element-75">
                        <label  >If you are not already retired, when do you want to retire?</label>
                        <textarea rows="4" className='input76' id="add_info_retired" cols="50" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','add_info_retired')}}></textarea>
                    </div>

                    <div className="textbox-other column-2" id="element-76">
                        <label  >How did you acquire your wealth?</label>
                        <textarea rows="4" className='input77' id="add_info_acquire_wealth" cols="50" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','add_info_acquire_wealth')}}></textarea>
                    </div>
                </div>

                <div>
                    <div className="textbox-other column-2" id="element-77">
                        <label  >How did you acquire your wealth?</label>
                        <textarea rows="4" className='input78' id="add_info_acquire_wealth2" cols="50" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','add_info_acquire_wealth2')}}></textarea>
                    </div>

                    <div className="textbox-other column-2" id="element-78">
                        <label  >If something happens to you, who gets cared for?</label>
                        <textarea rows="4" className='input79' id="add_info_gets_cared" cols="50" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','add_info_gets_cared')}}></textarea>
                    </div>
                </div>

            </div>
            <div className="container_small_fix row form-heading">
                <span>Please pick the top two for your retirement “nest egg” money. Why?</span>
            </div>

            <div className="container_small_fix">
                <div className="row w500" id="element-79">
                    <div className="check" >
                        <input type="checkbox" className='input80' id="safely" onChange={savefornestegg}/> SAFETY
                    </div>

                    <div className="check">
                        <input type="checkbox" className='input81' id="liquidity" onChange={savefornestegg}/> LIQUIDITY
                    </div>

                    <div className="check">
                        <input type="checkbox" id="growth" className='input82' onChange={savefornestegg}/> GROWTH
                    </div>

                    <div className="check">
                        <input type="checkbox" id="income" className='input83' onChange={savefornestegg}/> INCOME
                    </div>
                </div>
            </div>
            <div className="container_small_fix row form-heading">
                <span>How would you describe your investment knowledge?</span>
            </div>

            <div className="container_small_fix">
                <div className="row w700" id="element-80">
                    <div className="check">
                        <input type="radio" className='input84' name="investment_knowledge" value="None"   onChange={()=>{autosavevalues('radio','investment_knowledge')}}/> NONE
                    </div>

                    <div className="check">
                        <input type="radio" className='input85' name="investment_knowledge" value="Average" onChange={()=>{autosavevalues('radio','investment_knowledge')}} /> AVERAGE
                    </div>

                    <div className="check">
                        <input type="radio" className='input86' name="investment_knowledge" value="Limited" onChange={()=>{autosavevalues('radio','investment_knowledge')}}/> LIMITED
                    </div>

                    <div className="check">
                        <input type="radio" className='input87' name="investment_knowledge" value="Good" onChange={()=>{autosavevalues('radio','investment_knowledge')}}/> GOOD
                    </div>

                    <div className="check">
                        <input type="radio" className='input88' name="investment_knowledge" value="High" onChange={()=>{autosavevalues('radio','investment_knowledge')}}/> HIGH
                    </div>

                    <div className="check">
                        <input type="radio" className='input89' name="investment_knowledge" value="Expert" onChange={()=>{autosavevalues('radio','investment_knowledge')}}/> EXPERT
                    </div>
                </div>
            </div>
            <div className="container_small_fix row" id="element-81">
                <div>
                    <div className="textbox-other column-2">
                        <label  >Client Health</label>
                        <textarea rows="4" className='input90' cols="50" id="client_health" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','client_health')}}></textarea>
                    </div>

                    <div className="textbox-other column-2">
                        <label  >Spouse Health</label>
                        <textarea rows="4" cols="50" className='input91' id="spouse_health" placeholder="Type Here... *" onChange={()=>{autosavevalues('text','spouse_health')}}></textarea>
                    </div>
                </div>
            </div>
            </div>
             <Innerfooter />
        </div>
    )
}

import React from 'react'
import Innerfooter from './Innerfooter'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';
import Disableinnerheader from './Disableinnerheader';
import { useEffect } from 'react';

export default function Addprofile_partthree() {
    const navigate = useNavigate();
    var top_three_concerns = '';
    var financial_objectives = '';
    var risk_tolerance = '';

    var dataarrr = [];
    var trgfield_value = '';
    var tgrgfield_name = '';


    useEffect(()=>{
        var luid =  localStorage.getItem('uid');
        var obj = {
            LUID:luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/view_stepthreeform.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result!=="fail"){                          
                    dataarrr = result;
                    console.log(dataarrr);
                    $("input[name='risk_tolerance'][value='"+dataarrr[0].risk_tolerance+"'").prop('checked', true);
                    $('#sign').val(dataarrr[0].sign);
                    $('#sign_date').val(dataarrr[0].sign_date)
                    var threecon = dataarrr[0].top_three_concerns
                    if(threecon!==''){
                        var arr = threecon.split('|');
                        var len = arr.length;
                        for(var i=0;i<len;i++){
                            var vals = arr[i];
                            if(vals==='Losing too much money in the stock market'){
                                $('#loose_stock_market').prop('checked',true)
                            }
                            if(vals==='Avoid paying too much in taxes'){
                                $('#avoid_pay_extra_tax').prop('checked',true)
                            }
                            if(vals==='Considering retirement and not sure if I/we can afford to'){
                                $('#considering_retirement').prop('checked',true)
                            }
                            if(vals==='Not having a reliable income plan for retirement'){
                                $('#reliable_income_plan').prop('checked',true)
                            }
                            if(vals==='Concerned about giving away life savings due to a catastrophic illness'){
                                $('#catastrophic_illness').prop('checked',true)
                            }
                            if(vals==='Outliving nest egg'){
                                $('#outliving_nest_egg').prop('checked',true)
                            }
                            if(vals==='Uncertainty about stock market'){
                                $('#uncertain_stock_market').prop('checked',true)
                            }
                            if(vals==='Leaving a legacy to children and/or grandchildren'){
                                $('#leving_a_legacy').prop('checked',true)
                            }
                            if(vals==='Need direction with 401(k) and/or IRA accounts'){
                                $('#ira_account').prop('checked',true)
                            }                      
                            
                        }

                       
                    }
                    var finspl = dataarrr[0].financial_objectives;
                    var farr = finspl.split('|');
                    var flen = farr.length;
                    for(var i=0;i<flen;i++){
                        var vals = farr[i];
                        if(vals==='Income Now/Later'){
                            $('#income_now').prop('checked',true)
                        } 
                        
                        if(vals==='Growth Potential'){
                            $('#growth').prop('checked',true)
                        } 
                        if(vals==='Reduce Fees'){
                            $('#reduce_fee').prop('checked',true)
                        }
                        if(vals==='Reduce Risk'){
                            $('#reduce_risk').prop('checked',true)
                        }
                        if(vals==='Reduce Taxes'){
                            $('#reduce_fax').prop('checked',true)
                        }
                        if(vals==='Pass to Beneficiaries'){
                            $('#beneficiaries').prop('checked',true)
                        }
                       
                    }
                   
                }
            },
            });
    });

   


    function checkTopConcern(){
        var luid = localStorage.getItem('uid');
        top_three_concerns = '';
        if ($('#loose_stock_market').prop('checked')) {
            top_three_concerns += 'Losing too much money in the stock market|'
        }
        if ($('#avoid_pay_extra_tax').prop('checked')) {
            top_three_concerns += 'Avoid paying too much in taxes|'
        }
        if ($('#considering_retirement').prop('checked')) {
            top_three_concerns += 'Considering retirement and not sure if I/we can afford to|'
        }
        if ($('#reliable_income_plan').prop('checked')) {
            top_three_concerns += 'Not having a reliable income plan for retirement|'
        }
        if ($('#catastrophic_illness').prop('checked')) {
            top_three_concerns += 'Concerned about giving away life savings due to a catastrophic illness|'
        }
        if ($('#outliving_nest_egg').prop('checked')) {
            top_three_concerns += 'Outliving nest egg|'
        }
        if ($('#uncertain_stock_market').prop('checked')) {
            top_three_concerns += 'Uncertainty about stock market|'
        }
        if ($('#leving_a_legacy').prop('checked')) {
            top_three_concerns += 'Leaving a legacy to children and/or grandchildren|'
        }
        if ($('#ira_account').prop('checked')) {
            top_three_concerns += 'Need direction with 401(k) and/or IRA accounts|'
        }

        tgrgfield_name = 'top_three_concerns';
        savetoserver(top_three_concerns,tgrgfield_name,luid)
    }


    function financeobjset(){
        var luid = localStorage.getItem('uid');
        financial_objectives = '';
        if ($('#income_now').prop('checked')) {
            financial_objectives += $('#income_now').val()+'|'
        }
        if ($('#growth').prop('checked')) {
            financial_objectives += $('#growth').val()+'|'
        }
        if ($('#reduce_fee').prop('checked')) {
            financial_objectives += $('#reduce_fee').val()+'|'
        }
        if ($('#reduce_risk').prop('checked')) {
            financial_objectives += $('#reduce_risk').val()+'|'
        }
        if ($('#reduce_fax').prop('checked')) {
            financial_objectives += $('#reduce_fax').val()+'|'
        }
        if ($('#beneficiaries').prop('checked')) {
            financial_objectives += $('#beneficiaries').val()+'|'
        }
        tgrgfield_name = 'financial_objectives';
        savetoserver(financial_objectives,tgrgfield_name,luid)
    }


    function autosavevalues(type,id){
        var luid =  localStorage.getItem('uid');
        if(type==='text'){
            trgfield_value = $('#'+id).val();
            tgrgfield_name = id
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }else if(type==='radio'){
            trgfield_value = $("input[name='"+id+"']:checked").val();
            tgrgfield_name = id;
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }

    }

    function savetoserver(val,fldname,uid){
        var obj = {
            trgvalue:val,
            LUID:uid,
            trgfield:fldname
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/autosavestepthree.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result.trim()!=="fail"){
                //                          
                }
            },
            });
    }
    function gonext(){

        var luid =  localStorage.getItem('uid'); 
        var obj = {
            iscomplete:'button_step3',
            LUID:luid
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/autosavestep_button.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result.trim()!=="fail"){                          
                    console.log(result); 
                    navigate('/downloadpdf');           
                }
            },
            });           
           
    }

    function goback(){
        navigate('/addprofile_parttwo'); 
    }
   
  return (
    <div>
      <Disableinnerheader />
      <div class="container_small spacer"></div>
      <div class="container _small">
            <div class="f-timeline">

                <div class="step">
                    <div class="line-t"></div>
                    <div class="circle-num">
                        <div class="c1 completephase">1</div>
                        <div class="c2 completephase">2</div>
                        <div class="c3 runningphase">3</div>
                    </div>
                </div>

                <div class="arrowed-opt">
                    <div class="ar-1">1. Contact Information</div>
                    <div class="ar-2" >2. Cash Flow</div>
                    <div class="ar-3" >3. Concerns</div>
                </div>

            </div>
        </div>

        <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                CONCERNS
            </div>

         <div class="container_small_fix row form-heading">
                <span>Which of the following are your top three concerns?</span>
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="checkbox" id="loose_stock_market" value="Losing money in the stock market" onChange={checkTopConcern}/> Losing too much money in the stock market
                        </div>
                        
                        <div class="check">
                            <input type="checkbox" id="avoid_pay_extra_tax" value="Avoid paying too much in taxes" onChange={checkTopConcern}/> Avoid paying too much in taxes
                        </div>
        
                        <div class="check">
                            <input type="checkbox" id="considering_retirement" value="Considering retirement and not sure if I/we can afford to" onChange={checkTopConcern}/> Considering retirement and not sure if I/we can afford to 
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reliable_income_plan" value="Not having a reliable income plan" onChange={checkTopConcern}/> Not having a reliable income plan for retirement 
                        </div>

                        <div class="check">
                            <input type="checkbox" id="catastrophic_illness" value="Concerned about giving away life savings due to a catastrophic illness" onChange={checkTopConcern}/> Concerned about giving away life savings due to a catastrophic illness
                        </div>

                        <div class="check">
                            <input type="checkbox" id="outliving_nest_egg" value="Outliving nest egg"/> Outliving nest egg
                        </div>

                        <div class="check">
                            <input type="checkbox" id="uncertain_stock_market" value="Uncertainty about stock market" onChange={checkTopConcern}/> Uncertainty about stock market
                        </div>

                        <div class="check">
                            <input type="checkbox" id="leving_a_legacy" value="Leaving a legacy to children and/or grandchildren" onChange={checkTopConcern}/> Leaving a legacy to children and/or grandchildren
                        </div>

                        <div class="check">
                            <input type="checkbox" id="ira_account" value="Need direction with 401(k) and/or IRA accounts " onChange={checkTopConcern}/> Need direction with 401(k) and/or IRA accounts 
                        </div>
                    </div>
                </div>                

            </div>
            </div>
            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                RISK TOLERANCE
            </div>
            <div class="container_small_fix row form-heading">
                <span>Which of the following describes your risk tolerance when it comes to retirement assets?</span>
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="radio" value="Conservative" name="risk_tolerance"   onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Conservative
                        </div>
                        
                        <div class="check">
                            <input type="radio" value="Moderate" name="risk_tolerance" onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Moderate
                        </div>

                        <div class="check">
                            <input type="radio" value="Aggressive" name="risk_tolerance" onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Aggressive
                        </div>
        
                        
                    </div>
                </div>

            </div>
            </div>
            <div class="container_small row form-heading">
                <span>What are your financial objectives? (Check all that apply)</span>
            </div>
            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                FINANCIAL OBJECTIVES
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="checkbox" id="income_now" value="Income Now/Later" onChange={financeobjset}/> Income Now/Later
                        </div>
                        
                        <div class="check">
                            <input type="checkbox" id="growth" value="Growth Potential" onChange={financeobjset}/> Growth Potential
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_fee" value="Reduce Fees" onChange={financeobjset}/> Reduce Fees
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_risk" value="Reduce Risk" onChange={financeobjset}/> Reduce Risk
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_fax" value="Reduce Taxes" onChange={financeobjset}/> Reduce Taxes
                        </div>

                        <div class="check">
                            <input type="checkbox" id="beneficiaries" value="Pass to Beneficiaries" onChange={financeobjset}/> Pass to Beneficiaries
                        </div>
        
                        
                    </div>
                </div>

            </div>
           </div>
            <div class="container_small row">
                <div>
                    <div class="textbox-other column-2">
                        <label for="">Signature</label>
                        <textarea rows="4" id="sign" cols="50" placeholder="Sign..." onChange={()=>{autosavevalues('text','sign')}}></textarea>
                    </div>
                </div>
            
            </div>


            <div class="container_small row">

                <div class="input-form">
                    <label for="">Date *</label>
                    <input type="date" id="sign_date" placeholder="" onChange={()=>{autosavevalues('text','sign_date')}}/>
                </div>
            </div>
           

            <div class="container_small">
            <div className='row newdisplay'>
                <button onClick={goback} class="btn-next bckbtn">Back</button>
                <button onClick={gonext} class="btn-next">Next</button>                
            </div>
            </div>


      <Innerfooter />
    </div>
  )
}

import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { useEffect } from 'react';
import $ from 'jquery';
import Waiting_time from '../images/Waiting-time.gif'
export default function Downlaod_pdf() {
  var pdflink = '';

  useEffect(() => {
    var LUID = localStorage.getItem('uid');
    var obj = {
      LUID: LUID
    }
    console.log(obj);
    $.ajax({
      type: 'POST',
      url: 'https://retirement.jondaknows.com/generate_pdf.php',
      data: obj,
      cache: false,
      success: function (result) {
        console.log(result);
        if (result.trim() !== "fail") {
          pdflink = result;
        }
      },
    });

    chkapplystatus();

    const script = document.createElement('script');
    script.src = 'https://cdn.apptoto.com/v5/embed.js';
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  })

  function chkapplystatus() {
    var LUID = localStorage.getItem('uid');
    var obj = {
      LUID: LUID
    }
    $.ajax({
      type: 'POST',
      url: 'https://retirement.jondaknows.com/checkprocessstatus.php',
      data: obj,
      cache: false,
      success: function (result) {
        console.log(result);
        if (result.trim() !== "fail") {
          $('#mainheading h3').text('YOUR RETIREMENT PLAN IS READY FOR REVIEW');
          $('#clock').css('display', 'none');
          $('#downloadbtncell').css('display', 'none');
          $('#calender').css('display', 'block');
          $('#calender').css('display', 'block');
          $('#downinfo').css('display', 'none');
          $('#processdowninfo').css('display', 'block');
          $('#process_dwnload_opt').css('display', 'block');
          $('#instruc').text('Please book an appointment below');
          $('#infotxt').css('display','none');
        }
      },
    });
  }
  function goforpdf() {
    var targeturl = pdflink;
    window.open(targeturl, '_blank');
  }
  return (
    <div>
      <Innerheader />
      <div className="default-h min-100vh">
        <div className="container">
          <div className="download-section-auto">
            <div className="download-heading" id="mainheading"><h3>YOUR RETIREMENT PLAN IS BEING PROCESSED</h3></div>
            <div className="waiting">
              <div className="waiting-time">
                <div className="clk-time" id="clock"><img src={Waiting_time} alt="Download pdf" /></div>
                <div className="waiting-txt"><p id="instruc">Give us three to five business days to have it ready</p></div>
              </div>
            </div>
            <div className="waiting-txt-download">
              <h5 id="downinfo">Your <span classNameName="spancolor">PDF</span> is ready to <span classNameName="spancolor">Download</span></h5>
              <h6 id="infotxt">Please click the button below to download it</h6>
            </div>
            <div className="account-info">
              <div className="content-box">
                <div className="input-form align-content" id="downloadbtncell">
                  <button type="button" onClick={goforpdf}>
                    <span>Download</span>
                    <span className="play-ic"><i className="ri-arrow-down-line"></i></span>
                  </button>
                </div>
                <div className='fullwidth pt-50 nodisplay' id="calender">
                  <div className="calenderholder">
                    <iframe src="https://jondalowe.apptoto.com/?for_iframe=true&min_height=300&booking_form_only=true" allowTransparency="true" />
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
      <Innerfooter />
    </div>
  )
}

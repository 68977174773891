import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Component/Login';
import Sign_up from './Component/Sign_up';
import Request_password from './Component/Request_password';
import Request_confirmation from './Component/Request_confirmation';
import Profile from './Component/Profile';
import Previoussiginup from './Component/Previoussiginup';
import Logout from './Component/Logout';
import Accountsetting from './Component/Accountsetting';
import Addprofile_partone from './Component/Addprofile_partone';
import Addprofile_parttwo from './Component/Addprofile_parttwo';
import Addprofile_partthree from './Component/Addprofile_partthree';
import Profile_partone from './Component/Profile_partone';
import Returntostepone from './Component/Returntostepone';
import Profile_parttwo from './Component/Profile_parttwo';
import Profile_partthree from './Component/Profile_partthree';
import Downlaod_pdf from './Component/Downlaod_pdf';
import Notification from './Component/Notification';



function App() {
  return (
    <section className='singleheight'>
    <Router>     
    <div className='fullwidth'>         
      <Routes>       
      <Route exact path="/" element={<Login/>} />
      <Route exact path="/signup_steptwo" element={<Sign_up/>}/>
      <Route exact path="/requestpassword" element={<Request_password/>}/>
      <Route exact path="/requestcont" element={<Request_confirmation/>}/>
      <Route exact path="/profile" element={<Profile/>}/>
      <Route exact path="/Signup_stepone" element={<Previoussiginup/>}/>
      <Route exact path="/logout" element={<Logout/>}/>
      <Route exact path="/accountsetting" element={<Accountsetting/>}/>
      <Route exact path="/addprofile_partone" element={<Addprofile_partone/>}/>
      <Route exact path="/addprofile_parttwo" element={<Addprofile_parttwo/>}/>
      <Route exact path="/addprofile_partthree" element={<Addprofile_partthree/>}/>
      <Route exact path="/profilepartone" element={<Profile_partone/>}/>
      <Route exact path="/returnforupdate" element={<Returntostepone/>}/>
      <Route exact path="/profileparttwo" element={<Profile_parttwo/>}/>
      <Route exact path="/profilepartthree" element={<Profile_partthree/>}/>
      <Route exact path="/downloadpdf" element={<Downlaod_pdf/>}/>
      <Route exact path="/notification" element={<Notification/>}/>
      

      </Routes>  

    </div>
    </Router>
  </section>
  );
}

export default App;

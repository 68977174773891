import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { Link } from 'react-router-dom'
import $ from 'jquery';

export default function Accountsetting() {
    return (
        <div>
            <Innerheader />

            <div className="dash-nav">
                <ul className="row">
                    <li><Link to="/profile">Profile</Link></li>
                    <li><Link to="" className="active">Account Settings</Link></li>
                </ul>
               </div>
                <div class="default-h">
                <div className="container_small column-2">

                    <div className="container row form-heading">
                        Your Account Info
                    </div>

                    <div className="input-form">
                        <label >Username</label>
                        <input type="text" placeholder="Username"/>
                    </div>

                    <div className="spacer"></div>

                    <div className="input-form">
                        <label >Password</label>
                        <input type="password" placeholder="Password"/>
                    </div>
                </div>

                
            
            <div className="container spacer"></div>
            </div>
            <Innerfooter />
           
        </div>
    )
}

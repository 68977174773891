import React from 'react'
import Accountheader from './Accountheader';
import Googleimg from '../images/gl.jpg'
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import $ from 'jquery';

export default function Sign_up() {
    const navigate = useNavigate();
    useEffect(()=>{
        var usemail = localStorage.getItem('useremail');
        $('#umail').val(usemail);
    })
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }

      function formatPhoneNumber(inputid) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');
        
        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }
        
        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
        
        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    function userSignup(){
       
      
        var fname = $('#fname').val();
        var lname = $('#lname').val();
        var phone = $('#phone').val();
        var umail = $('#umail').val();
        var pass = $('#pass').val();
        var cpass = $('#cpass').val();

       if(fname===''){
        alert('Please insert your first name.');
       }else if(lname===''){
        alert('Please insert your last name');
       }else if(phone===''){
        alert('Please insert your phone number');
       }else if(umail===''){
        alert('Please insert your email');
       }else if(pass===''){
        alert('Please insert your password');
       }else if(pass!==cpass){
        alert('Your password not matched');
       }else{


        if(validateEmail(umail)){
            
            var obj = {
                fname:fname,
                lname:lname,
                mobile:phone,
                uemail:umail,
                pass:pass
                
            }
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: 'https://retirement.jondaknows.com/signup.php',
                data: obj,
                cache: false,
                success: function(result) { 
                    console.log(result);
                   if(result.trim()!=="fail"){                           
                        console.log(result);                   
                        var arr= result.split('|');
                        var uname = arr[0];
                        var uemail = arr[1];
                        var upass = arr[2];
                        var uid = arr[3];
                        var uphone = arr[4];
                        localStorage.setItem('uname',uname);
                        localStorage.setItem('uemail',uemail);
                        localStorage.setItem('uid',uid);
                        localStorage.setItem('uphone',uphone);
                        navigate('/addprofile_partone');              
                    }else{
                        alert('Please cannot create multiple account with same email or phone number.')
                        navigate('/'); 
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
       }
    }
    return (
        <div>
            <Accountheader/>
            <div  className="forms">



                <div  className="pop-up"><span>Already have an account? <span  className="bold"><Link to="/" >Sign In</Link></span></span> <i  className="ri-close-fill close-btn"></i></div>

                <div  className="form">

                    <div  className="form-content">



                        <h3>Personal Information</h3>

                        <div className='formarea'>

                            <div  className="media-options">
                                <a href="https://retirement.jondaknows.com/google-oauth.php"  className="field google">
                                    <img src={Googleimg} alt=""  className="google-img"/>
                                        <span>Continue With Google</span>
                                </a>
                            </div>

                            <div  className="line"></div>

                            <div  className="field">
                            <input type="text"  id="fname" placeholder="First Name"/>
                        </div>

                        <div  className="field">
                            <input type="text"  id="lname" placeholder="Last Name"/>
                        </div>

                        <div  className="field">
                            <input type="tel"  id="phone" placeholder="Mobile Number" onInput={()=>{formatPhoneNumber('phone')}}/>
                        </div>

                        <div  className="field">
                            <input type="email"  id="umail" placeholder="Email"/>
                        </div>

                        <div  className="field">
                            <input type="password"  id="pass" placeholder="Enter Password"/>
                        </div>
    
                        <div  className="field">
                            <input type="password"  id="cpass" placeholder="Confirm Password"/>
                        </div>

                            <div  className="field">
                                <button onClick={userSignup}>Create Account</button>
                            </div>

                            <div  className="field privacy-txt">
                                <p>By signing up I agree to the JondaKnows Privacy Policy and Terms of Service.</p>
                            </div>

                       

                      </div>

                    </div>

                </div>

                <div  className="privacy-section"><i  className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>
        </div>
    )
}

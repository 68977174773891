import React from 'react'

export default function Innerfooter() {
  return (
    <div className='fullwidth pt-100'>
      <div  className="footer-dsk">
            <div  className="copyright">Copyright © 2024 - JondaKnows. All Rights Reserved</div>
            <div  className="privacy-policy">
                <div  className="termsofservice">Terms of Service</div>
                <div  className="privacy">Privacy Policy</div>
            </div>
        </div>
    </div>
  )
}

import React from 'react';
import Accountheader from './Accountheader';
import Check from '../images/check.png'
import Footer from './Footer';
import {Link, useNavigate} from 'react-router-dom';

export default function Request_confirmation() {
    return (
        <div>
            <Accountheader/>
            <div class="forms">
                <div class="form">
                    <div class="form-content">
                        <div class="password-reset-pop">
                            <div class="pass-reset align-item-center">
                                <div class="heading column">
                                    <img src={Check} alt=""/>
                                        <h2>Password Link Sent</h2>
                                        <p>Please check your  inbox user@domian.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="privacy-section"><i class="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>
        </div>
    )
}

import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { Link } from 'react-router-dom'
import $ from 'jquery';


export default function Profile() {
  return (
    <div>
     
      <Innerheader/>
    <div className="dash-nav">
 
        
        <ul className="row">
            <li><Link to="" className="active">Profile</Link></li>
            <li><Link to="/accountsetting">Account Settings</Link></li>
        </ul>
       

    </div>

    <div className="default-h">

        

        <div className="container spacer"></div>

        
        <div className="container_small pt-100">
                <div className="container row form-heading pb-50">
                    1: PERSONAL INFORMATION
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Name *</label>
                        <input type="text" placeholder="Full Name... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Nickname *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Date of Birth *</label>
                        <input type="date" placeholder=""/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Working At/Retired From *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Current/Former Occupation *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Retired? *</label>
                        <input type="text" placeholder="Type..."/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Spouse Name *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Nickname *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Date of Birth *</label>
                        <input type="date" placeholder=""/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Working At/Retired From *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Current/Former Occupation *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Retired? *</label>
                        <input type="text" placeholder="Type..."/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Home# *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Cell# *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Email *</label>
                        <input type="email" placeholder="user@domain.com"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Primary Address Street / City / State / ZIP *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >Were you referred to us? *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
    
                    <div className="input-form">
                        <label  >If so, by whom? *</label>
                        <input type="text" placeholder="Type Here... *"/>
                    </div>
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                   1: PART 2-  FAMILY DETAILS
                </div>
    
                <div className="container row">
                    <div className="input-form-2">
                        <label  >Child 1 Name</label>
                        <input type="text" placeholder="Child 1 Fullname"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Age</label>
                        <input type="text" placeholder="Age"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Number of Grandchildren</label>
                        <input type="text" placeholder="Number of Grandchildren"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Grandchildren Ages</label>
                        <input type="text" placeholder="Grandchildren Ages"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form-2">
                        <label  >Child 2 Name</label>
                        <input type="text" placeholder="Child 2 Fullname"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Age</label>
                        <input type="text" placeholder="Age"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Number of Grandchildren</label>
                        <input type="text" placeholder="Number of Grandchildren"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Grandchildren Ages</label>
                        <input type="text" placeholder="Grandchildren Ages"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form-2">
                        <label  >Child 3 Name</label>
                        <input type="text" placeholder="Child 3 Fullname"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Age</label>
                        <input type="text" placeholder="Age"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Number of Grandchildren</label>
                        <input type="text" placeholder="Number of Grandchildren"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Grandchildren Ages</label>
                        <input type="text" placeholder="Grandchildren Ages"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form-2">
                        <label  >Child 4 Name</label>
                        <input type="text" placeholder="Child 4 Fullname"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Age</label>
                        <input type="text" placeholder="Age"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Number of Grandchildren</label>
                        <input type="text" placeholder="Number of Grandchildren"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Grandchildren Ages</label>
                        <input type="text" placeholder="Grandchildren Ages"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form-2">
                        <label  >Child 5 Name</label>
                        <input type="text" placeholder="Child 5 Fullname"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Age</label>
                        <input type="text" placeholder="Age"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Number of Grandchildren</label>
                        <input type="text" placeholder="Number of Grandchildren"/>
                    </div>
    
                    <div className="input-form-2">
                        <label  >Grandchildren Ages</label>
                        <input type="text" placeholder="Grandchildren Ages"/>
                    </div>
                </div>
    
                <div className="container row">
                    <div className="input-form">
                        <label  >Who are your trustees and/or<br/>executor?</label>
                        <input type="text" placeholder="Type Here..."/>
                    </div>
    
                    <div className="input-form">
                        <label  >Do any of your children or grandchildren have special needs?</label>
                        <div className="checkbox">
                            <div className="yes-check">
                                <input type="checkbox"/> Yes
                            </div>
                            <div className="no-check">
                                <input type="checkbox"/> No
                            </div>
                        </div>
                    </div>
    
                    <div className="input-form">
                        <label  >Are any of your children or grandchildren listed as joint owners?</label>
                        <div className="checkbox">
                            <div className="yes-check">
                                <input type="checkbox"/> Yes
                            </div>
                            <div className="no-check">
                                <input type="checkbox"/> No
                            </div>
                        </div>
                    </div>
    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                    1: PART 3- LEGAL ITEMS
                </div>
    
                <div className="container row form-heading">
                    <span>Which of the following documents do you have?</span>
                </div>
    
                <div className="container row">
                
                    <div className="column-2">
                        <div className="input-form">
                        
                            <div className="checkbox">
                                <span>Will</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form">
                            
                            <div className="checkbox">
                                <span>Power of Attorney (POA) Assets</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form">
                            
                            <div className="checkbox">
                                <span>Living Trust</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="column-2">
                        <div className="input-form">
                        
                            <div className="checkbox">
                                <span>Power of Attorney (POA) Health</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form">
                            
                            <div className="checkbox">
                                <span>Living Will</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form-date">
                            <div className="last-updated">
                                <span>Date Last Updated *</span>
                                <input type="date" placeholder=""/>
                            </div>
                        </div>
                    </div>
                    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                   1: PART 4 - ADD'L ITEMS
                </div>
    
    
                <div className="container row">
                
                    <div className="column-2">
                        <div className="input-form">
                        
                            <div className="checkbox">
                                <span>Umbrella Policy</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form">
                            
                            <div className="checkbox">
                                <span>Have you prepaid your funeral?</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-life-insurance">
                            <div className="insurance">
                                <span>Life Insurance</span>
                                <input type="text" placeholder="Type here..."/>
                            </div>
                        </div>
                    </div>
    
                    <div className="column-2">
                        <div className="input-form">
                        
                            <div className="checkbox">
                                <span>Long-Term Care Insurance</span>
                                <div className="yes-check">
                                    <input type="checkbox"/> Yes
                                </div>
                                <div className="no-check">
                                    <input type="checkbox"/> No
                                </div>
                            </div>
                        </div>
            
                        <div className="input-form">
                            
                            <div className="checkbox-death-benefit">
                                <span>Death Benefit Type</span>
                                
                                <div className="benefit-options">
                                    <div className="whole-check">
                                        <input type="checkbox"/> Whole
                                    </div>
                                    <div className="term-check">
                                        <input type="checkbox"/> Term
                                    </div>
                                    <div className="universal-check">
                                        <input type="checkbox"/> Universal
                                    </div>
                                </div>
                                
                            </div>
                        </div>
            
                        <div className="input-life-insurance">
                            <div className="insurance row">
                                <span>Life Insurance</span>
                                <input type="text" placeholder="Type here..."/>
                            </div>
                        </div>
                    </div>
                    
                </div>


               
                <div className="container row form-heading pb-50 pt-50">
                    2: PART 1 - CASHFLOW
                </div>
    
                <div className="container row form-heading">
                    <span>Please list monthly income from each source:</span>
                </div>
    
                <div className="container row">
                    
                    <div className="colmn-form column-2">
                        
                        <span>Social Security</span>
    
                        <div className="form-n row">
                            <div className="input-form-part-2 column-2">
                                <label  >Husband *</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Wife *</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
                        </div>
    
    
                        <span>Pension</span>
    
                        <div className="form-n row">
                            <div className="input-form-part-2 column-2">
                                <label  >Husband *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Wife *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
    
                        <span>(Survivor Options)</span>
    
                        <div className="form-n row">
                            <div className="input-form-part-2 column-2">
                                <label  >Husband *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Wife *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span >Is your current cash flow sufficient and<br/>comfortable?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Do you take any withdrawals from your<br/>retirement savings to meet your current<br/>budget?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Do you anticipate any significant<br/>changes in cash flow ?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                    <div className="colmn-form column-2">
                        
                        <span>Wages</span>
    
                        <div className="form-n row">
                            <div className="input-form-part-2 column-2">
                                <label  >Husband *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Wife *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <span>Other Income</span>
    
                        <div className="form-n row">
                            <div className="input-form-part-2 column-2">
                                <label  >Husband *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Wife *</label>
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Are these amounts net or gross?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="gross">
                                            <input type="checkbox"/> Gross
                                        </div>
                                        <div className="net">
                                            <input type="checkbox"/> Net
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                        <div className="input-life-insurance">
                            <div className="insurance">
                                <span>How much are your monthly expenses?</span>
                                <input type="text" placeholder="Type here..."/>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Are you planning any major lifestyle<br/>changes?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Do you foresee any large purchases<br/>greater than
                                        $5,000 within the next<br/>three years ?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                        <div>
                            <div className="input-form">
                            
                                <div className="checkbox-death-benefit">
                                    <span>Do you contribute to charity?</span>
                                    
                                    <div className="benefit-options">
                                        <div className="whole-check">
                                            <input type="checkbox"/> Yes
                                        </div>
                                        <div className="term-check">
                                            <input type="checkbox"/> No
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
    
                    </div>            
                    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                    2: PART 2 - LIFE EVENTS
                </div>
    
                <div className="container row form-heading">
                    <span>In the near future I expect to: (Please check all that apply)</span>
                </div>
    
                <div className="container">
                    
                    <div className="row">
                        <div className="column-2 f-2 w400">
                            <div className="check">
                                <input type="checkbox"/> Buy a home
                            </div>
                            
                            <div className="check">
                                <input type="checkbox"/> Care for a parent
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Sell a home
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Start/Expand a business
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Sell a property
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Improve a home
                            </div>
                        </div>
    
                        <div className="column-2 f-2 w500">
    
                            <div className="check">
                                <input type="checkbox"/> Pay off Debt
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Receive an inheritance
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Retire
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Start a part-time job
                            </div>
            
                            <div className="check">
                                <input type="checkbox"/> Purchase a property
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Help fund education costs for a family member
                            </div>
                        </div>
    
                    </div>
    
                        <div className="column-2 other-options">
                            <div className="row">
                                <div className="check">
                                    <input type="checkbox"/> Other
                                </div>
                            </div>
            
                            <div className="text-area-other">
                                <textarea rows="4" cols="50" placeholder="Type Here..."></textarea>
                            </div>
                        </div>
    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                    2: PART 3 - ASSETS
                </div>
    
                <div className="container row form-heading">
                    <span>Please check of the accounts you currently hold, note the approximate value and bring in the latest statement.</span>
                </div>
    
    
                <div className="container row">
                    <div>
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Buy a home
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> CDs
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Mutual Funds / Stocks / Bonds
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Brokerage Accounts
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Business Interest
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Annuities
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                    </div>
    
                    <div>
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Retirement Accounts from Work
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> IRAs / 401(K) / 403(B) / Keoghs / TSAs
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Life Insurance
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Long-Term Care Insurance
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Promissory Notes / Contract for Deed
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="check">
                                <input type="checkbox"/> Other Assets
                            </div>
    
                            <div className="textbox-other">
                                <input type="text" placeholder="Type Here... *"/>
                            </div>
                        </div>
    
                    </div>
                </div>
    
    
                <div className="container row">
                    
                    <div className="colmn-form column-2">
                        
                        <span>Property:</span>
    
                        <div className="form-n row mb-20" >
                            <div className="input-form-part-2 column-2">
                                <label  >Home Value $</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Balance Owed</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payment</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payoff date</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
                        </div>
                        
                        <div className="form-n row mb-20" >
                            <div className="input-form-part-2 column-2">
                                <label  >Autos and Personal Property $</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Balance Owed</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payment</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payoff date</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
                        </div>
    
                        <div className="form-n row mb-20" >
                            <div className="input-form-part-2 column-2">
                                <label  >Rental/Add’l Properties $</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Balance Owed</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payment</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
    
                            <div className="input-form-part-2 column-2">
                                <label  >Payoff date</label>
                                <input type="text" placeholder="SSN Type Here... *"/>
                            </div>
                        </div>
                    </div>
    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                    2: PART 4 - ADD'L INFORMATION
                </div>
    
                <div className="container row form-heading">
                    <span>Please check of the accounts you currently hold, note the approximate value and bring in the latest statement.</span>
                </div>
    
    
                <div className="container row">
                    <div>
                        <div className="textbox-other column-2">
                            <label  >If you are not already retired, when do you want to retire?</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
    
                        <div className="textbox-other column-2">
                            <label  >How did you acquire your wealth?</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
                    </div>
    
                    <div>
                        <div className="textbox-other column-2">
                            <label  >How did you acquire your wealth?</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
    
                        <div className="textbox-other column-2">
                            <label  >If something happens to you, who gets cared for?</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
                    </div>
                    
                </div>
    
    
                <div className="container row form-heading">
                    <span>Please pick the top two for your retirement “nest egg” money. Why?</span>
                </div>
    
                <div className="container">
                    <div className="row w500">
                        <div className="check">
                            <input type="checkbox"/> SAFETY
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> LIQUIDITY
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> GROWTH
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> INCOME
                        </div>
                    </div>
                </div>
    
                <div className="container row form-heading">
                    <span>How would you describe your investment knowledge?</span>
                </div>
    
                <div className="container">
                    <div className="row w700">
                        <div className="check">
                            <input type="checkbox"/> NONE
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> AVERAGE
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> LIMITED
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> GOOD
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> HIGH
                        </div>
    
                        <div className="check">
                            <input type="checkbox"/> EXPERT
                        </div>
                    </div>
                </div>
    
                <div className="container row">
                    <div>
                        <div className="textbox-other column-2">
                            <label  >Client Health</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
    
                        <div className="textbox-other column-2">
                            <label  >Spouse Health</label>
                            <textarea rows="4" cols="50" placeholder="Type Here... *"></textarea>
                        </div>
                    </div>
                
                </div>
              



               
                <div className="container row form-heading pb-50 pt-50">
                    3: PART 1 - CONCERNS
                </div>
    
                <div className="container row form-heading">
                    <span>Which of the following are your top three concerns?</span>
                </div>
    
                <div className="container row">
                    
                    <div>
                        <div className="column-2 f-3">
                            <div className="check">
                                <input type="checkbox"/> Losing too much money in the stock market
                            </div>
                            
                            <div className="check">
                                <input type="checkbox"/> Avoid paying too much in taxes
                            </div>
            
                            <div className="check">
                                <input type="checkbox"/> Considering retirement and not sure if I/we can afford to 
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Not having a reliable income plan for retirement 
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Concerned about giving away life savings due to a catastrophic illness
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Outliving nest egg
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Uncertainty about stock market
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Leaving a legacy to children and/or grandchildren
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Need direction with 401(k) and/or IRA accounts 
                            </div>
                        </div>
                    </div>
    
                    
    
                </div>
    
    
                <div className="container row form-heading pb-50 pt-50">
                    3: PART 2 - OBJECTIVES
                </div>
    
                <div className="container row form-heading">
                    <span>Which of the following describes your risk tolerance when it comes to retirement assets?</span>
                </div>
    
                <div className="container row">
                    
                    <div>
                        <div className="column-2 f-3">
                            <div className="check">
                                <input type="checkbox"/> Conservative
                            </div>
                            
                            <div className="check">
                                <input type="checkbox"/> Moderate
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Aggressive
                            </div>
            
                            
                        </div>
                    </div>
    
                </div>
    
    
                <div className="container row form-heading">
                    <span>What are your financial objectives? (Check all that apply)</span>
                </div>
    
                <div className="container row">
                    
                    <div>
                        <div className="column-2 f-3">
                            <div className="check">
                                <input type="checkbox"/> Income Now/Later
                            </div>
                            
                            <div className="check">
                                <input type="checkbox"/> Growth Potential
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Reduce Fees
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Reduce Risk
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Reduce Taxes
                            </div>
    
                            <div className="check">
                                <input type="checkbox"/> Pass to Beneficiaries
                            </div>
            
                            
                        </div>
                    </div>
    
                </div>
    
                <div className="container row">
                    <div>
                        <div className="textbox-other column-2">
                            <label  >Signature</label>
                            <textarea rows="4" cols="50" placeholder="Sign..."></textarea>
                        </div>
                    </div>
                
                </div>
    
    
                <div className="container row">
    
                    <div className="input-form">
                        <label  >Date *</label>
                        <input type="date" placeholder=""/>
                    </div>
                </div>
    
               


                
                
    
    
           
        </div>
        

       



        <div className="container spacer"></div>
        <Innerfooter/>
    
    </div>

    </div>
  )
}

import React from 'react';
import Accountheader from './Accountheader';
import Googleimg from '../images/gl.jpg'
import Footer from './Footer';
import {Link, useNavigate} from 'react-router-dom';
import $ from 'jquery';



export default function Login() {
    const navigate = useNavigate();
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }

    function userlogin(){
       
      
        var uemail = $('#useremail').val();
        var upass = $('#pass').val();
       if(uemail===''){
        alert('Please insert your registered email.');
       }else if(upass===''){
        alert('Please insert your password');
       }else{
        if(validateEmail(uemail)){
            
            var obj = {
                username:uemail,
                pass:upass
                
            }
            $.ajax({
                type: 'POST',
                url: 'https://retirement.jondaknows.com/login.php',
                data: obj,
                cache: false,
                success: function(result) { 
                    console.log(result);
                   if(result.trim()!=="fail"){                          
                        console.log(result);                
                    
                        var arr= result.split('|');
                        var uname = arr[0];
                        var uemail = arr[1];
                        var upass = arr[2];
                        var uid = arr[3];
                        var uphone = arr[4];
                        var profilecomp = arr[5];
                        localStorage.setItem('uname',uname);
                        localStorage.setItem('uemail',uemail);
                        localStorage.setItem('uid',uid);
                        localStorage.setItem('uphone',uphone);
                        if(profilecomp==="0"||profilecomp===""){
                            navigate('/addprofile_partone'); 
                        }else if(profilecomp==="1"){
                            navigate('/addprofile_parttwo'); 
                        }else if(profilecomp==="2"){
                            navigate('/addprofile_partthree'); 
                        }else if(profilecomp==="3"){
                            navigate('/profilepartone'); 
                        }
                                         
                        
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
       }
    }

    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("deactivetopbar");
    }
    
    return (
        <div>
            <div  className="fullwidth">

                <Accountheader/>

                <div  className="forms">
                <div  className="pop-up" id="topbar"><span>Don't have an account? <span  className="bold"><Link to="/Signup_stepone" >Sign Up</Link></span></span> <i  className="ri-close-fill close-btn" onClick={()=>{offTheBar('topbar')}}></i></div>

                    <div  className="form">
                    

                        <div  className="form-content">

                            <h3>Sign In</h3>

                            <div className='formarea'>

                                <div  className="media-options">
                                    <a href="https://retirement.jondaknows.com/google-oauth.php"  className="field google">
                                        <img src={Googleimg} alt=""  className="google-img"/>
                                            <span>Continue With Google</span>
                                    </a>
                                </div>

                                <div  className="line"></div>

                                <div  className="field input-field">
                                    <input type="text" id="useremail"  className="input" placeholder="name@domain.com"/>
                                </div>

                                <div  className="field input-field">
                                    <input type="password" id="pass"   className="password fullwidth" placeholder="Password" />
                                </div>

                                <div  className="field input-field">
                                    <button onClick={userlogin}>Sign In</button>
                                </div>

                                <div  className="field input-field bottomlink">
                                    <p>Don't have an account?
                                        <span  className="bold"><Link to="/Signup_stepone" >Sign Up</Link></span></p>
                                    <p>Forgot Password?
                                        <span  className="bold"><Link to="/requestpassword" >Click here</Link></span></p>
                                </div>

                           
                                </div>


                        </div>

                    </div>

                    <div  className="privacy-section"><i  className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

                </div>
                

                
            </div>
            <Footer/>
        </div>
    )
}

import React from 'react'
import Accountheader from './Accountheader';
import Googleimg from '../images/gl.jpg'
import Footer from './Footer';
import {Link, useNavigate} from 'react-router-dom';
import $ from 'jquery';

export default function Request_password() {
    const navigate = useNavigate();
    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }

    function resetpass(){
       
      
        var uemail = $('#useremail').val();
       
       if(uemail===''){
        alert('Please insert your registered email.');
       }else{
        if(validateEmail(uemail)){
            
            var obj = {
                useremail:uemail,
                
                
            }
            $.ajax({
                type: 'POST',
                url: 'https://retirement.jondaknows.com/resetpass.php',
                data: obj,
                cache: false,
                success: function(result) { 
                            
                   if(result.trim()!=="fail"){   
                        
                        console.log(result);            
                    
                       
                        navigate('/requestcont');                   
                        
                    }
                },
                });
        }else{
            alert(' Please insert a valid email')
        }
       }
    }

    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("deactivetopbar");
    }
    return (
        <div>
            <Accountheader/>
            <div  className="forms">
            <div  className="pop-up" id="topbar"><span>Don't have an account? <span  className="bold"><Link to="/Signup_stepone" >Sign Up</Link></span></span> <i  className="ri-close-fill close-btn" onClick={()=>{offTheBar('topbar')}}></i></div>

                <div  className="form">

                    <div  className="form-content">

                        <h3>Password Reset</h3>
                        <p>To reset your password, enter the email address you use to sign in</p>

                        <div className='formarea'>
                            <div  className="field input-field">
                                <input type="text"  className="input" id="useremail" placeholder="E-mail Address"/>
                            </div>

                            <div  className="field input-field">
                                <button onClick={resetpass}>Send Reset Link</button>
                            </div>
                        </div>



                    </div>

                </div>

                <div  className="privacy-section"><i  className="ri-lock-2-line pad-lock"></i> <b>Privacy & Security Protected</b></div>

            </div>
            <Footer/>
        </div>
    )
}

import React from 'react';
import Brand_id from '../images/brand-id.png';

export default function Accountheader() {
  return (
    <div>
      <div class="nav-dsk">
                    <div class="brand">
                        <a href="index.html"><img src={Brand_id} alt=""/></a>
                    </div>
                    <p>Transforming how people think about money</p>
                </div>
    </div>
  )
}

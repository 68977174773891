import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';

export default function Profile_partone() {
    const navigate = useNavigate();
    var indexarr = [];
    var counter = 0;
    var trgfield_value = '';
    var tgrgfield_name = '';
    var dataarrr = [];
    var chcounter = 2;
    var editmoce = 0;
    useEffect(() => {
        //  $("input[name='child_special_needs'][value='yes'").prop('checked', true);;
        var luid = localStorage.getItem('uid');
        var obj = {
            LUID: luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/view_steponeform.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result !== "fail") {
                    dataarrr = result;
                    $("input[name='LTC_insurance'][value='" + dataarrr[0].LTC_insurance + "'").prop('checked', true);
                    $('#by_whoom').val(dataarrr[0].by_whoom)
                    $('#cell_phone').val(dataarrr[0].cell_phone)
                    $('#child_age1').val(dataarrr[0].child_age1)
                    $('#child_age2').val(dataarrr[0].child_age2)
                    $('#child_age3').val(dataarrr[0].child_age3)
                    $('#child_age4').val(dataarrr[0].child_age4)
                    $('#child_age5').val(dataarrr[0].child_age5)
                    $('#child_age5').val(dataarrr[0].child_age5)
                    $("input[name='child_listed'][value='" + dataarrr[0].child_listed + "'").prop('checked', true);
                    $('#child_name1').val(dataarrr[0].child_name1)
                    $('#child_name2').val(dataarrr[0].child_name2)
                    $('#child_name3').val(dataarrr[0].child_name3)
                    $('#child_name4').val(dataarrr[0].child_name4)
                    $('#child_name5').val(dataarrr[0].child_name5)
                    $("input[name='child_special_needs'][value='" + dataarrr[0].child_special_needs + "'").prop('checked', true);
                    $("input[name='death_benifit'][value='" + dataarrr[0].death_benifit + "'").prop('checked', true);
                    $('#dob').val(dataarrr[0].dob)
                    $('#doc_DLU').val(dataarrr[0].doc_DLU)
                    $("input[name='doc_POA_asset'][value='" + dataarrr[0].doc_POA_asset + "'").prop('checked', true);
                    $("input[name='doc_POA_health'][value='" + dataarrr[0].doc_POA_health + "'").prop('checked', true);
                    $("input[name='doc_living_trust'][value='" + dataarrr[0].doc_living_trust + "'").prop('checked', true);
                    $("input[name='doc_will'][value='" + dataarrr[0].doc_will + "'").prop('checked', true);
                    $('#email').val(dataarrr[0].email)
                    $('#grandchildren_age1').val(dataarrr[0].grandchildren_age1)
                    $('#grandchildren_age2').val(dataarrr[0].grandchildren_age2)
                    $('#grandchildren_age3').val(dataarrr[0].grandchildren_age3)
                    $('#grandchildren_age4').val(dataarrr[0].grandchildren_age4)
                    $('#grandchildren_age5').val(dataarrr[0].grandchildren_age5)
                    $('#home_phone').val(dataarrr[0].home_phone)
                    $('#life_insurance').val(dataarrr[0].life_insurance)
                    $('#life_insurance2').val(dataarrr[0].life_insurance2)
                    $('#name').val(dataarrr[0].name)
                    $('#nickname').val(dataarrr[0].nickname)
                    $('#nickname').val(dataarrr[0].nickname)
                    $("input[name='prepaid_funeral'][value='" + dataarrr[0].prepaid_funeral + "'").prop('checked', true);
                    $('#primary_address').val(dataarrr[0].primary_address)
                    $('#referred_to_us').val(dataarrr[0].referred_to_us)
                    $('#referred_to_us').val(dataarrr[0].referred_to_us)
                    $('#retired').val(dataarrr[0].retired)
                    $('#sp_dob').val(dataarrr[0].sp_dob)
                    $('#sp_nickname').val(dataarrr[0].sp_nickname)
                    $('#sp_occupation').val(dataarrr[0].sp_occupation)
                    $('#sp_retired').val(dataarrr[0].sp_retired)
                    $('#sp_working_at').val(dataarrr[0].sp_working_at)
                    $('#spouse_name').val(dataarrr[0].spouse_name)
                    $('#trustees_executor').val(dataarrr[0].trustees_executor)
                    $("input[name='umb_policy'][value='" + dataarrr[0].umb_policy + "'").prop('checked', true);
                    $('#working_at').val(dataarrr[0].working_at)

                }
            },

        });


        gofornewstyle();
        function gofornewstyle() {
            var trgcount = 41;
            for (var i = 1; i < trgcount; i++) {
                var tgrgcls = 'input' + i;
                $('.' + tgrgcls).attr('readonly', 'true')
                $('.' + tgrgcls).addClass('makeptag')

            }
            $('.radiocover').css('display', 'block')
        }

    })

    

    


    function openChildoption() {
        var newchild = 'childrow' + chcounter;
        $('#' + newchild).css('display', 'flex');
        surpluscouter();
    }
    function surpluscouter() {
        chcounter++;
        if (chcounter > 5) {
            chcounter = 2;
        }
    }

    function hidechicl(id) {
        $('#' + id).css('display', 'none')
    }

    function makeeditable() {
        if (editmoce === 0) {
            var trgcount = 67;
            for (var i = 1; i < trgcount; i++) {
                var tgrgcls = 'input' + i;
                $('.' + tgrgcls).removeAttr('readonly')
                $('.' + tgrgcls).removeClass('makeptag')
            }
            $('.radiocover').css('display', 'none')
            $('#editform').text('Cancel');
            editmoce = 1;
        } else {
            var trgcount = 41;
            for (var i = 1; i < trgcount; i++) {
                var tgrgcls = 'input' + i;
                $('.' + tgrgcls).attr('readonly', 'true')
                $('.' + tgrgcls).addClass('makeptag')
            }
            $('.radiocover').css('display', 'block')
            $('#editform').text('Edit')
            editmoce = 0;
        }

    }

    function autosavevalues(type, id) {
        var luid = localStorage.getItem('uid');
        if (type === 'text') {
            trgfield_value = $('#' + id).val();
            tgrgfield_name = id
            savetoserver(trgfield_value, tgrgfield_name, luid)
        } else if (type === 'radio') {
            trgfield_value = $("input[name='" + id + "']:checked").val();
            tgrgfield_name = id;
            savetoserver(trgfield_value, tgrgfield_name, luid)
        }

    }
    function savetoserver(val, fldname, uid) {
        var obj = {
            trgvalue: val,
            LUID: uid,
            trgfield: fldname
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/autosavestepone.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result.trim() !== "fail") {
                    //                          
                }
            },
        });
    }

    function formatPhoneNumber(inputid) {
        var inputv = document.getElementById(inputid);
        // Remove all characters from the input except digits
        var phoneNumber = inputv.value.replace(/\D/g, '');

        // Check if the input is empty or if it's not a valid phone number
        if (phoneNumber.length === 0 || phoneNumber.length > 10) {
            inputv.value = '';
            alert('Phone number is invalid')
            return;
        }

        // Format the phone number
        var formattedPhoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);

        // Update the input value
        inputv.value = formattedPhoneNumber;
    }

    return (
        <div>
            <Innerheader />
            <div className="container_small profile_part_btn h90">
                <div className='container_small_fix nopadding'>
                    <span><button className="selectedbtn">Personame/ Contact Information</button></span>
                    <span><Link to="/profileparttwo" >Cash Flow</Link></span>
                    <span><Link to="/profilepartthree" >Concerns</Link></span>
                    <span className='toright'><button className="transselectedbtn" onClick={makeeditable} id="editform">Edit</button></span>
                    
                </div>
            </div>

            <div className='sectionholder'>
                <div className="container_small_fix row form-heading area-title">
                    YOUR DETAILS
                </div>

                <div className="container_small_fix row">
                    <div className="input_form_32" id="element-0" >
                        <label>Name *</label>
                        <input type="text" className='input1' id="name" placeholder="Full Name... *" onChange={() => { autosavevalues('text', 'name') }} />
                    </div>

                    <div className=" input_form_32" id="element-1">
                        <label>Nickname *</label>
                        <input type="text" className='input2' id="nickname" placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'nickname') }} />
                    </div>

                    <div className=" input_form_32" id="element-2">
                        <label >Date of Birth *</label>
                        <input type="date" className='input3' id="dob" placeholder="" onChange={() => { autosavevalues('text', 'dob') }} />
                    </div>
                </div>

                <div className="container_small_fix row ">
                    <div className=" input_form_32" id="element-3">

                        <label  >Working At/Retired From *</label>
                        <input type="text" className='input4' id="working_at" placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'working_at') }} />
                    </div>

                    <div className=" input_form_32" id="element-4">

                        <label  >Current/Former Occupation *</label>
                        <input type="text" className='input5' id="occupation" placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'occupation') }} />
                    </div>

                    <div className=" input_form_32" id="element-5">
                        <label  >Retired? *</label>
                        <input type="text" className='input6' id="retired" placeholder="Type..." onChange={() => { autosavevalues('text', 'retired') }} />
                    </div>
                </div>
            </div>
            <div className='sectionholder'>
                <div className="container_small_fix row form-heading area-title">
                    SPOUSE DETAILS
                </div>

                <div className="container_small_fix row">
                    <div className=" input_form_32" id="element-6">

                        <label  >Spouse Name *</label>
                        <input type="text" id="spouse_name" className='input7' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'spouse_name') }} />
                    </div>

                    <div className=" input_form_32" id="element-7">

                        <label  >Nickname *</label>
                        <input type="text" id="sp_nickname" className='input8' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'sp_nickname') }} />
                    </div>

                    <div className=" input_form_32" id="element-8">

                        <label  >Date of Birth *</label>
                        <input type="date" id="sp_dob" className='input9' placeholder="" onChange={() => { autosavevalues('text', 'sp_dob') }} />
                    </div>
                </div>

                <div className="container_small_fix row">
                    <div className=" input_form_32" id="element-9">
                        <label  >Working At/Retired From *</label>
                        <input type="text" id="sp_working_at" className='input10' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'sp_working_at') }}/>
                    </div>

                    <div className=" input_form_32" id="element-10">
                        <label  >Current/Former Occupation *</label>
                        <input type="text" id="sp_occupation" className='input11' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'sp_occupation') }} />
                    </div>

                    <div className=" input_form_32" id="element-11">
                        <label  >Retired? *</label>
                        <input type="text" id="sp_retired" className='input12' placeholder="Type..." onChange={() => { autosavevalues('text', 'sp_retired') }} />
                    </div>
                </div>
            </div>
            <div className='sectionholder'>
                <div className="container_small_fix row form-heading area-title">
                    CONTACT/REFER DETAILS
                </div>
                <div className="container_small_fix row">
                    <div className=" input_form_32" id="element-12">
                        <label  >Home# *</label>
                        <input type="text" id="home_phone" className='input13' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'home_phone') }} onInput={() => { formatPhoneNumber('home_phone') }} />
                    </div>

                    <div className=" input_form_32" id="element-13">
                        <label  >Cell#*</label>
                        <input type="text" id="cell_phone" className='input14' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'cell_phone') }} onInput={() => { formatPhoneNumber('cell_phone') }} />
                    </div>

                    <div className=" input_form_32" id="element-14">
                        <label  >Email *</label>
                        <input type="email" id="email" className='input15' placeholder="user@domain.com" onChange={() => { autosavevalues('text', 'email') }} />
                    </div>
                </div>

                <div className="container_small_fix row">
                    <div className=" input_form_32" id="element-15">
                        <label  >Primary Address Street / City / State / ZIP *</label>
                        <input type="text" id="primary_address" className='input16' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'primary_address') }} />
                    </div>

                    <div className=" input_form_32" id="element-16">
                        <label  >Were you referred to us? *</label>
                        <input type="text" id="referred_to_us" className='input17' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'referred_to_us') }} />
                    </div>

                    <div className=" input_form_32" id="element-17">
                        <label  >If so, by whom? *</label>
                        <input type="text" id="by_whoom" className='input18' placeholder="Type Here... *" onChange={() => { autosavevalues('text', 'by_whoom') }} />
                    </div>
                </div>
            </div>
            <div className='sectionholder pos-rel'>
                <div className="container_small_fix row form-heading area-title">
                    FAMILY
                </div>
                <div className='addmorechild'>
                    <button onClick={openChildoption}>+</button>
                </div>
                <div className="container_small_fix row" >
                    <div className=" input_form_24" id="element-18">
                        <label  >Child 1 Name</label>
                        <input type="text" id="child_name1" className='input19' placeholder="Child 1 Fullname" onChange={() => { autosavevalues('text', 'child_name1') }} />
                    </div>

                    <div className=" input_form_24" id="element-19">
                        <label  >Age</label>
                        <input type="text" id="child_age1" className='input20' placeholder="Age" onChange={() => { autosavevalues('text', 'child_age1') }} />
                    </div>

                    <div className=" input_form_24" id="element-20">
                        <label  >Number of Grandchildren</label>
                        <input type="text" id="no_grandchildren1" className='input21' placeholder="Number of Grandchildren" onChange={() => { autosavevalues('text', 'no_grandchildren1') }} />
                    </div>

                    <div className=" input_form_24" id="element-21">
                        <label  >Grandchildren Ages</label>
                        <input type="text" id="grandchildren_age1" className='input22' placeholder="Grandchildren Ages" onChange={() => { autosavevalues('text', 'grandchildren_age1') }} />
                    </div>
                </div>
                <div className="container_small_fix row nodisplay  pos-rel" id="childrow2">
                    <div className='minusbuttonholder'>
                        <button onClick={() => { hidechicl('childrow2') }}>-</button>
                    </div>
                    <div className=" input_form_24" id="element-22">

                        <label  >Child 2 Name</label>
                        <input type="text" id="child_name2" className='input23' placeholder="Child 2 Fullname" onChange={() => { autosavevalues('text', 'child_name2') }} />
                    </div>

                    <div className=" input_form_24" id="element-23">

                        <label  >Age</label>
                        <input type="text" id="child_age2" className='input24' placeholder="Age" onChange={() => { autosavevalues('text', 'child_age2') }} />
                    </div>

                    <div className=" input_form_24" id="element-24">

                        <label  >Number of Grandchildren</label>
                        <input type="text" id="no_grandchildren2" className='input25' placeholder="Number of Grandchildren" onChange={() => { autosavevalues('text', 'no_grandchildren2') }} />
                    </div>

                    <div className=" input_form_24" id="element-25">

                        <label  >Grandchildren Ages</label>
                        <input type="text" id="grandchildren_age2" className='input26' placeholder="Grandchildren Ages" onChange={() => { autosavevalues('text', 'grandchildren_age2') }} />
                    </div>
                </div>
                <div className="container_small_fix row nodisplay pos-rel" id="childrow3">
                    <div className='minusbuttonholder'>
                        <button onClick={() => { hidechicl('childrow3') }}>-</button>
                    </div>
                    <div className=" input_form_24" id="element-26">

                        <label  >Child 3 Name</label>
                        <input type="text" id="child_name3" className='input27' placeholder="Child 3 Fullname" onChange={() => { autosavevalues('text', 'child_name3') }} />
                    </div>

                    <div className=" input_form_24" id="element-27">

                        <label  >Age</label>
                        <input type="text" id="child_age3" className='input28' placeholder="Age" onChange={() => { autosavevalues('text', 'child_age3') }} />
                    </div>

                    <div className=" input_form_24" id="element-28">

                        <label  >Number of Grandchildren</label>
                        <input type="text" id="no_grandchildren3" className='input29' placeholder="Number of Grandchildren" onChange={() => { autosavevalues('text', 'no_grandchildren3') }} />
                    </div>

                    <div className=" input_form_24" id="element-29">

                        <label  >Grandchildren Ages</label>
                        <input type="text" id="grandchildren_age3" className='input30' placeholder="Grandchildren Ages" onChange={() => { autosavevalues('text', 'grandchildren_age3') }} />
                    </div>
                </div>
                <div className="container_small_fix row nodisplay pos-rel" id="childrow4">
                    <div className='minusbuttonholder'>
                        <button onClick={() => { hidechicl('childrow4') }}>-</button>
                    </div>
                    <div className=" input_form_24" id="element-30">

                        <label  >Child 4 Name</label>
                        <input type="text" id="child_name4" className='input31' placeholder="Child 4 Fullname" onChange={() => { autosavevalues('text', 'child_name4') }} />
                    </div>

                    <div className="input_form_24" id="element-31">
                        <label  >Age</label>
                        <input type="text" id="child_age4" className='input32' placeholder="Age" onChange={() => { autosavevalues('text', 'child_age4') }} />
                    </div>

                    <div className=" input_form_24" id="element-32">
                        <label  >Number of Grandchildren</label>
                        <input type="text" id="no_grandchildren4" className='input33' placeholder="Number of Grandchildren" onChange={() => { autosavevalues('text', 'no_grandchildren4') }} />
                    </div>

                    <div className=" input_form_24" id="element-33">
                        <label  >Grandchildren Ages</label>
                        <input type="text" id="grandchildren_age4" className='input34' placeholder="Grandchildren Ages" onChange={() => { autosavevalues('text', 'grandchildren_age4') }} />
                    </div>
                </div>
                <div className="container_small_fix row nodisplay pos-rel" id="childrow5">
                    <div className='minusbuttonholder'>
                        <button onClick={() => { hidechicl('childrow5') }}>-</button>
                    </div>
                    <div className=" input_form_24" id="element-34">
                        <label  >Child 5 Name</label>
                        <input type="text" id="child_name5" className='input35' placeholder="Child 5 Fullname" onChange={() => { autosavevalues('text', 'child_name5') }} />
                    </div>

                    <div className=" input_form_24" id="element-35">

                        <label  >Age</label>
                        <input type="text" id="child_age5" className='input36' placeholder="Age" onChange={() => { autosavevalues('text', 'child_age5') }} />
                    </div>

                    <div className=" input_form_24" id="element-36">
                        <label  >Number of Grandchildren</label>
                        <input type="text" id="no_grandchildren5" className='input37' placeholder="Number of Grandchildren" onChange={() => { autosavevalues('text', 'no_grandchildren5') }} />
                    </div>

                    <div className=" input_form_24" id="element-37">
                        <label  >Grandchildren Ages</label>
                        <input type="text" id="grandchildren_age5" className='input38' placeholder="Grandchildren Ages" onChange={() => { autosavevalues('text', 'grandchildren_age5') }} />
                    </div>
                </div>
            </div>
            <div className='sectionholder pos-rel'>
                <div className="container_small_fix row form-heading area-title">
                    TRUSTEES AND EXECUTOR
                </div>
                <div className="container_small_fix row">
                    <div className="input-form" id="element-38">
                        <label  >Who are your trustees and/or<br />executor?</label>
                        <input type="text" className='input39' id="trustees_executor" placeholder="Type Here..." />
                    </div>

                    <div className="input-form" id="element-39">
                        <label  >Do any of your children or grandchildren have special needs?</label>
                        <div className="checkbox">
                            <div className="yes-check radioholder">
                                <input type="radio" className='input40' name="child_special_needs" value="yes" onChange={() => { autosavevalues('radio', 'child_special_needs') }} /> Yes
                            </div>
                            <div className="no-check radioholder">
                                <input type="radio" className='input41' name="child_special_needs" value="no" onChange={() => { autosavevalues('radio', 'child_special_needs') }} /> No
                            </div>
                        </div>
                    </div>

                    <div className="input-form" id="element-40">
                        <div className='radiocover'>&nbsp;</div>
                        <label  >Are any of your children or grandchildren listed as joint owners?</label>
                        <div className="checkbox">
                            <div className="yes-check radioholder">
                                <input type="radio" className='input42' name="child_listed" value="yes" onChange={() => { autosavevalues('radio', 'child_listed') }} /> Yes
                            </div>
                            <div className="no-check radioholder">
                                <input type="radio" className='input43' name="child_listed" value="no" onChange={() => { autosavevalues('radio', 'child_listed') }} /> No
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='sectionholder pos-rel'>
                <div className="container_small_fix row form-heading area-title">
                    LEGAL ITEMS
                </div>
                <div className="container_small_fix row form-heading">
                    <span>Documents do you have.</span>
                </div>
                <div className="container_small_fix row">
                    <div className="column-2">
                        <div className="input-form" id="element-41">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Will</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" name="doc_will" className='input44' value="yes" onChange={() => { autosavevalues('radio', 'doc_will') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input45' name="doc_will" value="no" onChange={() => { autosavevalues('radio', 'doc_will') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form" id="element-42">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Power of Attorney (POA) Assets</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input46' name="doc_POA_asset" value="yes" onChange={() => { autosavevalues('radio', 'doc_POA_asset') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input47' name="doc_POA_asset" value="no" onChange={() => { autosavevalues('radio', 'doc_POA_asset') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form" id="element-43">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Living Trust</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input48' name="doc_living_trust" value="yes" onChange={() => { autosavevalues('radio', 'doc_living_trust') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input50' name="doc_living_trust" value="no" onChange={() => { autosavevalues('radio', 'doc_living_trust') }} /> No
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column-2">
                        <div className="input-form" id="element-44">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Power of Attorney (POA) Health</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input51' name="doc_POA_health" value="yes" onChange={() => { autosavevalues('radio', 'doc_POA_health') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input52' name="doc_POA_health" value="no" onChange={() => { autosavevalues('radio', 'doc_POA_health') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form" id="element-45">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Living Will</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input53' name="doc_living_will" value="yes" onChange={() => { autosavevalues('radio', 'doc_living_will') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input54' name="doc_living_will" value="no" onChange={() => { autosavevalues('radio', 'doc_living_will') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form-date" id="element-52">
                            <div className="last-updated ">
                                <span className='pr-20'>Date Last Updated *</span>
                                <input type="date" className='input55' id="doc_DLU" placeholder="" onChange={() => { autosavevalues('text', 'doc_DLU') }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='sectionholder pos-rel'>
                <div className="container_small_fix row form-heading area-title">
                    ADD'L ITEMS
                </div>

                <div className="container_small_fix row">
                    <div className="column-2">
                        <div className="input-form" id="element-46">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Umbrella Policy</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input56' name="umb_policy" value="yes" onChange={() => { autosavevalues('radio', 'umb_policy') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input57' name="umb_policy" value="no" onChange={() => { autosavevalues('radio', 'umb_policy') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form" id="element-47">
                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Have you prepaid your funeral?</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input58' name="prepaid_funeral" value="yes" onChange={() => { autosavevalues('radio', 'prepaid_funeral') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input59' name="prepaid_funeral" value="no" onChange={() => { autosavevalues('radio', 'prepaid_funeral') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-life-insurance pos-rel" id="element-51">
                            <div className="insurance row">
                                <span className='pr-20'>Life Insurance</span>
                                <input type="text" className='input60' id="life_insurance" placeholder="Type here..." onChange={() => { autosavevalues('text', 'life_insurance') }} />
                            </div>
                        </div>
                    </div>

                    <div className="column-2">
                        <div className="input-form" id="element-48">

                            <div className='radiocover'>&nbsp;</div>
                            <div className="checkbox">
                                <span>Long-Term Care Insurance</span>
                                <div className="yes-check radioholder">
                                    <input type="radio" className='input61' name="LTC_insurance" value="yes" onChange={() => { autosavevalues('radio', 'LTC_insurance') }} /> Yes
                                </div>
                                <div className="no-check radioholder">
                                    <input type="radio" className='input62' name="LTC_insurance" value="no" onChange={() => { autosavevalues('radio', 'LTC_insurance') }} /> No
                                </div>
                            </div>
                        </div>

                        <div className="input-form" id="element-49">


                            <div className="checkbox-death-benefit">
                                <span>Death Benefit Type</span>

                                <div className="benefit-options">
                                    <div className="whole-check radioholderlarge">
                                        <input type="radio" className='input63' name="death_benifit" value="Whole" onChange={() => { autosavevalues('radio', 'death_benifit') }} /> Whole
                                    </div>
                                    <div className="term-check radioholderlarge">
                                        <input type="radio" className='input64' name="death_benifit" value="Term" onChange={() => { autosavevalues('radio', 'death_benifit') }} /> Term
                                    </div>
                                    <div className="universal-check radioholderlarge">
                                        <input type="radio" className='input65' name="death_benifit" value="Universal" onChange={() => { autosavevalues('radio', 'death_benifit') }} /> Universal
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="input-life-insurance pos-rel" id="element-50">

                            <div className="insurance row">
                                <span className='pr-20'>Life Insurance</span>
                                <input type="text" id="life_insurance2" className='input66' placeholder="Type here..." onChange={() => { autosavevalues('text', 'life_insurance2') }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Innerfooter />
        </div>
    )
}

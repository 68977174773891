import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import User_IC from '../images/usr-ic.png';
import Notification from '../images/notification.png';
import Appjondaknows from '../images/appJondaKnows.png';
import { useEffect } from 'react';
import $ from 'jquery';

export default function Innerheader() {
    const navigate = useNavigate();
    var lud = localStorage.getItem('uid');
    setInterval(calltochknotice,1500);
useEffect(()=>{  
    var username = localStorage.getItem('uname'); 
    var userid = localStorage.getItem('uid');
    $('#user_name').text(username);
})
function toggleMenu(){
    console.log('hiii')
    let showpop = document.getElementById("pop-up");
    showpop.classList.toggle("show-usr-profile");
}

function gofornotification(){
    navigate('/notification');
}

function calltochknotice(){
    const obj = { LUID: lud };

    $.ajax({
        type: 'POST',
        url: 'https://retirement.jondaknows.com/chk_notification_unread.php',
        data: obj,
        cache: false,
        success: function (result) {
            console.log(result);
            if (result === '0') {
                $('.reddot').css('display','none')
            } else {
                $('.reddot').css('display','block')
                $('.reddot').text(result);
            }
        },
    });
}

  return (
    <div>
         <div className='yellowbar'>Questions? Call (304) 840-0706 to speak with an agent now</div>
      <div className="nav-dsks">
            
            <div className="brand-id">
                <img src={Appjondaknows} alt=""/>
            </div>
    
            <div className="user-controls">
                <div className="user">
                    <div className="usr-name" id="user_name"></div>
                    <div className="usr-icon">
                        <img src={User_IC} alt="" onClick={toggleMenu}/>
                        <i className="ri-arrow-down-s-fill"></i>
                    </div>
    
                    
                </div>
    
                <div className="usr-profile" id="pop-up">
                    <ul>
                        <li><Link to="/downloadpdf">Plan Status</Link></li>
                        <li><Link to="/profilepartone">Profile Information</Link></li>
                        <li><Link to="/accountsetting">Account Settings</Link></li>
                        
                        <li><Link to="/logout">Log Out <i className="ri-shut-down-line"></i></Link></li>
                    </ul>
                </div>
    
    
                <div className="notification">
                    <img src={Notification} alt="" onClick={gofornotification}/>
                    <span className="notificaton-count reddot">5</span>    
                    <div className="usr-notification" id="notification-pop-up">
                        <div className="notification-heading">Notifications</div>
                        <div className="messages-area">
                            
                            
                            <div className="message-item">
                            
                                <div className="message">No Messages</div>
                                
                            </div>
                            
                            <div className="all-msgs"><Link to="">See All Messages</Link></div>
                            
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
  )
}

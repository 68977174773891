import React from 'react'
import {Link, useNavigate} from 'react-router-dom';

export default function Footer() {
  return (
    <div>
      <div  className="footer">
                    <div  className="copyright">Copyright © 2024 - JondaKnows. All Rights Reserved.
                    Reserved</div>
                    <div  className="privacy-policy footerlink">
                        <div  className="termsofservice"><Link to="">Terms of Service</Link></div>
                        <div  className="privacy"><Link to="">Privacy Policy</Link></div>
                    </div>
                </div>
    </div>
  )
}

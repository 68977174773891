import React from 'react'
import Innerheader from './Innerheader'
import Innerfooter from './Innerfooter'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';

export default function Profile_partthree() {
   var dataarrr = [];
   var top_three_concerns = '';
   var financial_objectives = '';
   var trgfield_value = '';
   var tgrgfield_name = '';
   var editmoce = 0;
    useEffect(()=>{

        var luid =  localStorage.getItem('uid');
        var obj = {
            LUID:luid
        }
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/view_stepthreeform.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result!=="fail"){                          
                    dataarrr = result;
                    console.log(dataarrr);
                    $("input[name='risk_tolerance'][value='"+dataarrr[0].risk_tolerance+"'").prop('checked', true);
                    var threecon = dataarrr[0].top_three_concerns
                    if(threecon!==''){
                        var arr = threecon.split('|');
                        var len = arr.length;
                        for(var i=0;i<len;i++){
                            var vals = arr[i];
                            if(vals==='Losing too much money in the stock market'){
                                $('#loose_stock_market').prop('checked',true)
                            }
                            if(vals==='Avoid paying too much in taxes'){
                                $('#avoid_pay_extra_tax').prop('checked',true)
                            }
                            if(vals==='Considering retirement and not sure if I/we can afford to'){
                                $('#considering_retirement').prop('checked',true)
                            }
                            if(vals==='Not having a reliable income plan for retirement'){
                                $('#reliable_income_plan').prop('checked',true)
                            }
                            if(vals==='Concerned about giving away life savings due to a catastrophic illness'){
                                $('#catastrophic_illness').prop('checked',true)
                            }
                            if(vals==='Outliving nest egg'){
                                $('#outliving_nest_egg').prop('checked',true)
                            }
                            if(vals==='Uncertainty about stock market'){
                                $('#uncertain_stock_market').prop('checked',true)
                            }
                            if(vals==='Leaving a legacy to children and/or grandchildren'){
                                $('#leving_a_legacy').prop('checked',true)
                            }
                            if(vals==='Need direction with 401(k) and/or IRA accounts'){
                                $('#ira_account').prop('checked',true)
                            }
                            
                        }
                    }
                    var financeobjset = dataarrr[0].financial_objectives
                    if(threecon!==''){
                        var farr = financeobjset.split('|');
                        var flen = farr.length;
                        for(var i=0;i<flen;i++){
                            var vals = farr[i];
                            if(vals==='Income Now/Later'){
                                $('#income_now').prop('checked',true)
                            }
                            if(vals==='Growth Potential'){
                                $('#growth').prop('checked',true)
                            }
                            if(vals==='Reduce Fees'){
                                $('#reduce_fee').prop('checked',true)
                            }
                            if(vals==='Reduce Risk'){
                                $('#reduce_risk').prop('checked',true)
                            }
                            if(vals==='Reduce Taxes'){
                                $('#reduce_fax').prop('checked',true)
                            }
                            if(vals==='Pass to Beneficiaries'){
                                $('#beneficiaries').prop('checked',true)
                            }
                        }
                    }
                }
            },
            });


        gofornewstyle();
       function gofornewstyle(){
        var len = 63;
        for(var i=0;i<len;i++){
            var nid = 'element-' + i;
            var getarr = $('#'+nid+' input').attr('type');
            if(getarr!=='radio'||getarr!=='checkbox'){
                $('#'+nid+' input').attr('readonly','true');
                $('#'+nid+' input').addClass('makeptag');
            } 
            if(getarr==='radio'||getarr==='checkbox'){
                $('#'+nid+' input').attr('disabled','true');                
            }
            $('#'+nid+' textarea').attr('readonly','true');
            $('#'+nid+' textarea').addClass('makeptag');
                       
        }
        $('#liquidity').prop('checked', true);
       }
    })
    function readytoedit(id){
        var trgedit = 'element-' + id;
        $('#'+trgedit+' .each_editbtn').css('display','none')
        $('#'+trgedit+' .eachsavebtn').css('display','block')
        var exttext = $('#'+trgedit+' input').val();
        $('#'+trgedit+' input').attr('prevtext',exttext);
        var getarr = $('#'+trgedit+' input').attr('type');
        if(getarr!=='radio'||getarr!=='checkbox'){
            $('#'+trgedit+' input').removeAttr('readonly','true');
            $('#'+trgedit+' input').removeClass('makeptag');
        }
        if(getarr==='radio'||getarr==='checkbox'){
            $('#'+trgedit+' input').removeAttr('disabled');                
        }
        $('#'+trgedit+' textarea').removeAttr('readonly','true');
        $('#'+trgedit+' textarea').removeClass('makeptag');


    }

    function closeedit(id){
        var trgedit = 'element-' + id;
        $('#'+trgedit+' .each_editbtn').css('display','block')
        $('#'+trgedit+' .eachsavebtn').css('display','none')
        var getarr = $('#'+trgedit+' input').attr('type');
        if(getarr!=='radio'){
            var prevtext =  $('#'+trgedit+' input').attr('prevtext');
            $('#'+trgedit+' input').val(prevtext);
            $('#'+trgedit+' input').attr('readonly','true');
            $('#'+trgedit+' input').addClass('makeptag');
        }
        if(getarr=='radio'){
            $('#'+trgedit+' .radiocover').css('display','block')
        }
    }
    function checkTopConcern(){
        var luid = localStorage.getItem('uid');
        top_three_concerns = '';
        if ($('#loose_stock_market').prop('checked')) {
            top_three_concerns += 'Losing too much money in the stock market|'
        }
        if ($('#avoid_pay_extra_tax').prop('checked')) {
            top_three_concerns += 'Avoid paying too much in taxes|'
        }
        if ($('#considering_retirement').prop('checked')) {
            top_three_concerns += 'Considering retirement and not sure if I/we can afford to|'
        }
        if ($('#reliable_income_plan').prop('checked')) {
            top_three_concerns += 'Not having a reliable income plan for retirement|'
        }
        if ($('#catastrophic_illness').prop('checked')) {
            top_three_concerns += 'Concerned about giving away life savings due to a catastrophic illness|'
        }
        if ($('#outliving_nest_egg').prop('checked')) {
            top_three_concerns += 'Outliving nest egg|'
        }
        if ($('#uncertain_stock_market').prop('checked')) {
            top_three_concerns += 'Uncertainty about stock market|'
        }
        if ($('#leving_a_legacy').prop('checked')) {
            top_three_concerns += 'Leaving a legacy to children and/or grandchildren|'
        }
        if ($('#ira_account').prop('checked')) {
            top_three_concerns += 'Need direction with 401(k) and/or IRA accounts|'
        }

        tgrgfield_name = 'top_three_concerns';
        savetoserver(top_three_concerns,tgrgfield_name,luid)
    }


    function financeobjset(){
        var luid = localStorage.getItem('uid');
        financial_objectives = '';
        if ($('#income_now').prop('checked')) {
            financial_objectives += $('#income_now').val()+'|'
        }
        if ($('#growth').prop('checked')) {
            financial_objectives += $('#growth').val()+'|'
        }
        if ($('#reduce_fee').prop('checked')) {
            financial_objectives += $('#reduce_fee').val()+'|'
        }
        if ($('#reduce_risk').prop('checked')) {
            financial_objectives += $('#reduce_risk').val()+'|'
        }
        if ($('#reduce_fax').prop('checked')) {
            financial_objectives += $('#reduce_fax').val()+'|'
        }
        if ($('#beneficiaries').prop('checked')) {
            financial_objectives += $('#beneficiaries').val()+'|'
        }
        tgrgfield_name = 'financial_objectives';
        savetoserver(financial_objectives,tgrgfield_name,luid)
    }


    function autosavevalues(type,id){
        var luid =  localStorage.getItem('uid');
        if(type==='text'){
            trgfield_value = $('#'+id).val();
            tgrgfield_name = id
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }else if(type==='radio'){
            trgfield_value = $("input[name='"+id+"']:checked").val();
            tgrgfield_name = id;
            savetoserver(trgfield_value,tgrgfield_name,luid)
        }

    }

    function savetoserver(val,fldname,uid){
        var obj = {
            trgvalue:val,
            LUID:uid,
            trgfield:fldname
        }
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://retirement.jondaknows.com/autosavestepthree.php',
            data: obj,
            cache: false,
            success: function(result) { 
                console.log(result);
               if(result.trim()!=="fail"){
                //                          
                }
            },
            });
    }


    function makeeditable(){
        if(editmoce===0){
            var trgcount = 92;
        for(var i=1;i<trgcount;i++){
            var tgrgcls = 'input'+i;
            $('.'+tgrgcls).removeAttr('readonly')
            $('.'+tgrgcls).removeClass('makeptag')         
        }
        $('.radiocover').css('display','none')
        $('#editform').text('Cancel');
        editmoce = 1;
        }else{
            var trgcount = 41;
        for(var i=1;i<trgcount;i++){
            var tgrgcls = 'input'+i;
            $('.'+tgrgcls).attr('readonly','true')
            $('.'+tgrgcls).addClass('makeptag')
        }
        $('.radiocover').css('display','block')
        $('#editform').text('Edit')
        editmoce = 0;
        }     

    }

    function updateallfield(){

    }
  return (
    <div>
      <Innerheader />
      <div  className="container_small profile_part_btn h90">
               <span><Link to="/profilepartone" >Personame/ Contact Information</Link></span>
               <span><Link to="/profileparttwo" >Cash Flow</Link></span>
               <span><button className="selectedbtn">Concerns</button></span>
               <span className='toright'><button className="transselectedbtn" onClick={makeeditable} id="editform">Edit</button></span>
            </div>

         

            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                CONCERNS
            </div>

         <div class="container_small_fix row form-heading">
                <span>Your top three concerns?</span>
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="checkbox" id="loose_stock_market" value="Losing money in the stock market" onChange={checkTopConcern}/> Losing too much money in the stock market
                        </div>
                        
                        <div class="check">
                            <input type="checkbox" id="avoid_pay_extra_tax" value="Avoid paying too much in taxes" onChange={checkTopConcern}/>Avoid paying too much in taxes
                        </div>
        
                        <div class="check">
                            <input type="checkbox" id="considering_retirement" value="Considering retirement and not sure if I/we can afford to" onChange={checkTopConcern}/> Considering retirement and not sure if I/we can afford to 
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reliable_income_plan" value="Not having a reliable income plan" onChange={checkTopConcern}/> Not having a reliable income plan for retirement 
                        </div>

                        <div class="check">
                            <input type="checkbox" id="catastrophic_illness" value="Concerned about giving away life savings due to a catastrophic illness" onChange={checkTopConcern}/> Concerned about giving away life savings due to a catastrophic illness
                        </div>

                        <div class="check">
                            <input type="checkbox" id="outliving_nest_egg" value="Outliving nest egg"/> Outliving nest egg
                        </div>

                        <div class="check">
                            <input type="checkbox" id="uncertain_stock_market" value="Uncertainty about stock market" onChange={checkTopConcern}/> Uncertainty about stock market
                        </div>

                        <div class="check">
                            <input type="checkbox" id="leving_a_legacy" value="Leaving a legacy to children and/or grandchildren" onChange={checkTopConcern}/> Leaving a legacy to children and/or grandchildren
                        </div>

                        <div class="check">
                            <input type="checkbox" id="ira_account" value="Need direction with 401(k) and/or IRA accounts " onChange={checkTopConcern}/> Need direction with 401(k) and/or IRA accounts 
                        </div>
                    </div>
                </div>                

            </div>
            </div>
           

            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                RISK TOLERANCE
            </div>
            <div class="container_small_fix row form-heading">
                <span>Which of the following describes your risk tolerance when it comes to retirement assets?</span>
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="radio" value="Conservative" name="risk_tolerance"   onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Conservative
                        </div>
                        
                        <div class="check">
                            <input type="radio" value="Moderate" name="risk_tolerance" onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Moderate
                        </div>

                        <div class="check">
                            <input type="radio" value="Aggressive" name="risk_tolerance" onChange={()=>{autosavevalues('radio','risk_tolerance')}}/> Aggressive
                        </div>
        
                        
                    </div>
                </div>

            </div>
            </div>
            <div class="container_small row form-heading">
                <span>Your financial objectives.</span>
            </div>

            <div className='sectionholder'>
            <div className="container_small_fix row form-heading area-title">
                FINANCIAL OBJECTIVES
            </div>

            <div class="container_small_fix row">
                
                <div>
                    <div class="column-2 f-3">
                        <div class="check">
                            <input type="checkbox" id="income_now" value="Income Now/Later" onChange={financeobjset}/> Income Now/Later
                        </div>
                        
                        <div class="check">
                            <input type="checkbox" id="growth" value="Growth Potential" onChange={financeobjset}/> Growth Potential
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_fee" value="Reduce Fees" onChange={financeobjset}/> Reduce Fees
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_risk" value="Reduce Risk" onChange={financeobjset}/> Reduce Risk
                        </div>

                        <div class="check">
                            <input type="checkbox" id="reduce_fax" value="Reduce Taxes" onChange={financeobjset}/> Reduce Taxes
                        </div>

                        <div class="check">
                            <input type="checkbox" id="beneficiaries" value="Pass to Beneficiaries" onChange={financeobjset}/> Pass to Beneficiaries
                        </div>
        
                        
                    </div>
                </div>

            </div>
           </div>
           

           

            


      <Innerfooter />
    </div>
  )
}

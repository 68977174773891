import React from 'react'
import { Link } from 'react-router-dom';
import User_IC from '../images/usr-ic.png';
import Notification from '../images/notification.png';
import Appjondaknows from '../images/appJondaKnows.png';
import { useEffect } from 'react';
import $ from 'jquery';

export default function Disableinnerheader() {
    useEffect(()=>{  
        var username = localStorage.getItem('uname');  
        var userid = localStorage.getItem('uid');
        $('#user_name').text(username);
    })

    function finsihfirst(){
        alert('Please complete your profile first.')
    }
  return (
    <div>
        <div className='yellowbar'>Questions? Call (304) 840-0706 to speak with an agent now</div>
      <div className="nav-dsks">
            
            <div className="brand-id">
                <img src={Appjondaknows} alt=""/>
            </div>
    
            <div className="user-controls">
                <div className="user">
                    <div className="usr-name" id="user_name"></div>
                    <div className="usr-icon" onClick={finsihfirst}>
                        <img src={User_IC} alt=""/>
                        <i className="ri-arrow-down-s-fill"></i>
                    </div>
    
                    
                </div>
    
                <div className="usr-profile" id="pop-up">
                    <ul>
                        <li><button onClick={finsihfirst}>Profile Information</button></li>
                        <li><button onClick={finsihfirst}>Account Settings</button></li>
                        <li><Link to="/logout">Log Out <i className="ri-shut-down-line"></i></Link></li>
                    </ul>
                </div>
    
    
                <div className="notification" onClick={finsihfirst}>
                    <img src={Notification} alt="" />
                    <span className="notificaton-count">0</span>
    
                    <div className="usr-notification" id="notification-pop-up">
                        <div className="notification-heading"><button onClick={finsihfirst}>Notifications</button></div>
                        <div className="messages-area">
                            
                            
                            <div className="message-item">
                            
                                <div className="message">No Messages</div>
                                
                            </div>
                            
                            <div className="all-msgs"><Link to="">See All Messages</Link></div>
                            
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
  )
}
